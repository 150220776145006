export default function BudgetAndCosts() {
  const services = [
    {
      id: 1,
      name: "Cotización de Materiales, equipos, servicios, transportes para la construcción",
    },
    {
      id: 2,
      name: "Elaboración de análisis de precios unitarios APU",
    },
    {
      id: 3,
      name: "Presupuestos por actividades",
    },
    {
      id: 4,
      name: "Cotización de proyecto avalado por Ingeniero Civil",
    },
    {
      id: 5,
      name: "Seguimiento, control y actualización de los costos durante la ejecución de la obra civil",
    },
  ]
  return (
    <main>
      <div
        className="flex flex-col w-full h-[500px] justify-center items-center bg-center bg-cover sm:justify-center sm:items-start sm:gap-5 lg:h-[600px] "
        style={{
          backgroundImage: `url('https://res.cloudinary.com/dpj4akaog/image/upload/v1689884083/Indoles/Servicios%20Ingenieria/CostosYPresupuestos/BgCostsAndBudget_oiemy9.webp')`,
        }}
      >
        <div className="flex flex-col items-center gap-4 sm:items-start sm:ml-8 xl:ml-[100px]">
          <h1 className="text-3xl text-center text-white font-bold sm:text-left xl:text-[52px] ">
            COSTOS Y PRESUEPUESTOS
          </h1>
          <p className="font-medium text-2xl text-justify text-white  ">
            Cotizamos tu obra civil
          </p>
        </div>
      </div>
      <div className="flex flex-col items-center mx-8 my-12 gap-4 lg:mx-[100px] lg:my-[100px]">
        <h2 className="text-center font-bold text-2xl md:text-3xl">
          Acompañamos en la elaboración de la cotización
        </h2>
        <p className="text-base text-grayDark text-justify md:w-[600px]">
          Elaboramos el estudio de costos de los materiales, mano de obra,
          equipos y herramientas ,transporte y servicios relacionados con su
          obra civil
        </p>
        <div className="flex flex-col items-center mx-8 gap-10 mt-10 md:grid md:grid-cols-2">
          <div className="flex justify-center items-center px-4 py-4 rounded bg-grayCardCostsAndBudget xl:w-[480px] xl:h-[180px] xl:py-0">
            <div className="flex flex-col gap-3 ">
              <h3 className="text-base font-semibold">TIEMPO</h3>
              <p className="text-justify">
                Elaboración del cronograma de trabajo analizando los recursos
                necesarios para ejecutar el proyecto; evaluando el costo
                beneficio de su inversion.
              </p>
            </div>
          </div>
          <div className="flex justify-center items-center px-4 py-4 rounded bg-yellowLogo xl:w-[480px] xl:h-[180px] xl:py-0">
            <div className="flex flex-col gap-3">
              <h3 className="text-base font-semibold">CÁLCULO</h3>
              <p className="text-justify">
                Utilizamos rendimientos para la planificación de mano de obra,
                uso de equipos; a través de software para la administración de
                proyectos.
              </p>
            </div>
          </div>
          <div className="flex justify-center items-center px-4 py-4 rounded bg-blueLogo xl:w-[480px] xl:h-[180px] xl:py-0 ">
            <div className="flex flex-col gap-3">
              <h3 className="text-base text-white font-semibold ">
                IMPLEMENTACIÓN
              </h3>
              <p className="text-justify text-slate-200">
                Seguimiento y control de la ejecución del presupuesto de su
                obra.
              </p>
            </div>
          </div>
          <div className="flex justify-center items-center px-4 py-4 rounded bg-grayCardCostsAndBudget xl:w-[480px] xl:h-[180px] xl:py-0">
            <div className="flex flex-col gap-3">
              <h3 className="text-base font-semibold">PRESUPUESTO</h3>
              <p className="text-justify">
                Elaboramos análisis de precios unitarios para las obras civiles
                de los sectores de vivienda comercial, industrial e
                infraestructura.
              </p>
            </div>
          </div>
        </div>
      </div>
      <div className="grid place-items-center my-12 bg-slate-100 md:grid-cols-2">
        <img
          src="https://res.cloudinary.com/dpj4akaog/image/upload/v1689887933/Indoles/Servicios%20Ingenieria/CostosYPresupuestos/costsEngineer_cv8nhj.webp"
          alt="Cost Engineer "
        />
        <div className="flex flex-col mx-8 my-12 gap-5">
          <h2 className="text-center text-2xl font-bold md:text-3xl md:text-left ">
            Te apoyamos en tu proyecto
          </h2>
          <p className="text-justify text-grayDark ">
            Comunicate con nostros para prestarte la asesoría en la cotización
            de tu obra civil.
            <span className="flex font-semibold text-black gap-1">
              Servicio a nivel nacional{" "}
              <img
                width={24}
                height={24}
                src="/icons/flagColombia.svg"
                alt="Colombia flag"
              />
            </span>
          </p>
          <ul className="flex flex-col gap-4 text-grayDark">
            {services.map((service) => (
              <li className="flex gap-3" key={service.id}>
                <img
                  width={24}
                  height={24}
                  src="/icons/circleCheckSolid.svg"
                  alt="check icon"
                  loading="lazy"
                />
                {service.name}
              </li>
            ))}
          </ul>
        </div>
      </div>
      <div className="flex flex-col items-center mx-8 my-12 space-y-10 lg:my-[100px]">
        <h2 className="font-bold text-center text-2xl md:text-3xl md:text-left">
          Empresas que lograron resultados increbles
        </h2>
        <div className="flex flex-col py-4 px-4 items-center rounded border border-yellow-500 bg-grayCardCostsAndBudget md:w-[600px]">
          <img
            className="w-52"
            src="/partners/petroleosSudamericanos.webp"
            alt="Petroleos Sudamericanos"
          />
          <p className="font-medium text-sm text-justify">
            Optimización de tiempos de ejecución, uso eficiente de los recursos
            de la construcción, seguimiento y entrega de informes en el
            cumplimiento de los proyectos
          </p>
        </div>
      </div>
    </main>
  )
}
