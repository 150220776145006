import { useState } from "react"
import Carousel from "../components/Carousels/Carousel"
import LoadingState from "../components/LoadingState"
import Slider from "../components/Slider"
import UseGetPropertyById from "../hooks/UseGetPropertyById"
import { useLocation } from "react-router-dom"
import { FeaturesProperty } from "../components/FeaturesProperty"
import { CardDataProperty } from "../components/CardDataProperty"
export default function PropertyDetails() {
  const location = useLocation()
  const queryParams = new URLSearchParams(location.search)
  const id = queryParams.get("id")
  const [isOpenContact, setIsOpenContact] = useState(false)
  const { loading, propertyDetails } = UseGetPropertyById(id)
  const { city, neighborhood, street, state } = propertyDetails?.address || {}
  const {
    antiquity,
    builtArea,
    amountAdmin,
    privateArea,
    levels,
    cornerBack,
    floor,
    price,
    pricePerMeter,
    stratus,
  } = propertyDetails?.data || {}
  const {
    americanStyleKitchen,
    auxiliaryDiningRoom,
    americanStyleBar,
    bedroomHalls,
    bedrooms,
    bibliotecary,
    chimney,
    closet,
    clothingRoom,
    coveredParking,
    deposit,
    diningRoom,
    hall,
    integralKitchen,
    internalGarden,
    kitchen,
    laundryRoom,
    mainBathroom,
    marbledFloor,
    otherTypeOfFloor,
    roomBathroom,
    serviceRoom,
    socialBathroom,
    stairs,
    studyRoom,
    scortRoom,
    serviceBathrooms,
    tiledFloor,
    tvRoom,
    terrace,
    visitorParking,
    uncoveredParking,
    watsherAndDryer,
    woodenFloor,
  } = propertyDetails?.components || {}
  const { amenities, characteristics, commonZones, ofTheSector } = propertyDetails || {}
  if (loading) {
    return <LoadingState />
  }
  return (
    <main>
      <div
        className="relative"
        style={{
          background: "linear-gradient(180deg, #000 0%, rgba(51, 51, 51, 0.00) 100%)",
        }}
      >
        {/* Images Carousel */}
        <section className="hidden sm:flex sm:flex-col w-full items-center bg-transparent pt-10">
          <Slider media={propertyDetails?.media} />
        </section>
        {/* Images Slider */}
        <section className="flex w-full justify-center px-5">
          <Carousel media={propertyDetails?.media} />
        </section>
      </div>
      <div className="flex flex-col items-center my-12 lg:my-[100px]">
        <div className="max-w-4xl mx-10 space-y-10">
          {/* Back Button */}
          <button
            className="flex h-10 justify-center items-center rounded px-4 bg-yellowLogo"
            href="#"
            onClick={() => window.history.back()}
          >
            Volver
          </button>
          <h1 className=" flex flex-col items-center gap-2 text-center text-2xl font-medium md:text-3xl xl:flex-row xl:justify-between">
            {`${propertyDetails.propertyType} en ${propertyDetails.usage[0]} 
          ${neighborhood} ${city} ${state}`}
            <span className="font-medium font-2xl">${price.toLocaleString()}</span>
          </h1>
          {/* Description Section */}
          <section className="flex flex-col gap-3.5 ">
            <h2 className="font-medium text-lg xl:text-2xl">Descripción</h2>
            <span className="w-full h-1 bg-yellowLogo "></span>
            <p className=" text-justify text-sm">{propertyDetails.description}</p>
          </section>
          {/* Components Section */}
          {propertyDetails?.components &&
            (americanStyleKitchen > 0 ||
              auxiliaryDiningRoom > 0 ||
              americanStyleBar > 0 ||
              bedrooms > 0 ||
              bedroomHalls > 0 ||
              bibliotecary > 0 ||
              deposit > 0 ||
              chimney > 0 ||
              closet > 0 ||
              clothingRoom > 0 ||
              coveredParking > 0 ||
              diningRoom > 0 ||
              hall > 0 ||
              integralKitchen > 0 ||
              internalGarden > 0 ||
              kitchen > 0 ||
              laundryRoom > 0 ||
              mainBathroom > 0 ||
              marbledFloor > 0 ||
              otherTypeOfFloor > 0 ||
              roomBathroom > 0 ||
              serviceRoom > 0 ||
              socialBathroom > 0 ||
              stairs > 0 ||
              scortRoom > 0 ||
              serviceBathrooms > 0 ||
              studyRoom > 0 ||
              tiledFloor > 0 ||
              tvRoom > 0 ||
              visitorParking > 0 ||
              uncoveredParking > 0 ||
              watsherAndDryer > 0 ||
              woodenFloor > 0) && (
              <section className="flex flex-col gap-3.5 ">
                <h2 className="font-medium text-lg xl:text-2xl">Componentes del inmueble</h2>
                <span className="w-full h-1 bg-yellowLogo "></span>
                <div className="grid grid-cols-2 gap-x-10 gap-y-10 my-10 xl:grid-cols-4">
                  {auxiliaryDiningRoom > 0 && (
                    <CardDataProperty
                      label={auxiliaryDiningRoom}
                      url="https://res.cloudinary.com/dpj4akaog/image/upload/v1692642008/Indoles/Inmobiliaria/Icons/diningRoom_lnoktx.svg"
                      value="Comedor auxiliar"
                    />
                  )}
                  {americanStyleKitchen > 0 && (
                    <CardDataProperty
                      label={americanStyleKitchen}
                      url="https://res.cloudinary.com/dpj4akaog/image/upload/v1692642311/Indoles/Inmobiliaria/Icons/integralKitchen_baf6na.svg"
                      value="Cocina tipo americano"
                    />
                  )}
                  {americanStyleBar > 0 && (
                    <CardDataProperty
                      label={americanStyleBar}
                      url="https://res.cloudinary.com/dpj4akaog/image/upload/v1692639890/Indoles/Inmobiliaria/Icons/chariBar_imlojd.svg"
                      value="Barra estilo americano"
                    />
                  )}
                  {bedrooms > 0 && (
                    <CardDataProperty
                      label={bedrooms}
                      url="https://res.cloudinary.com/dpj4akaog/image/upload/v1692639278/Indoles/Inmobiliaria/Icons/bedroom_dehwbu.svg"
                      value="Habitaciones"
                    />
                  )}
                  {bedroomHalls > 0 && (
                    <CardDataProperty
                      label={bedroomHalls}
                      url="https://res.cloudinary.com/dpj4akaog/image/upload/v1692639586/Indoles/Inmobiliaria/Icons/bedroomHall_c0ftwq.svg"
                      value="Hall de habitaciones"
                    />
                  )}
                  {bibliotecary > 0 && (
                    <CardDataProperty
                      label={bibliotecary}
                      url="https://res.cloudinary.com/dpj4akaog/image/upload/v1692640292/Indoles/Inmobiliaria/Icons/library_ii4fmy.svg"
                      value="Biblioteca"
                    />
                  )}
                  {chimney > 0 && (
                    <CardDataProperty
                      label={chimney}
                      url=" https://res.cloudinary.com/dpj4akaog/image/upload/v1692640434/Indoles/Inmobiliaria/Icons/chimney_ivl6fl.svg"
                      value="Chimenea"
                    />
                  )}
                  {closet > 0 && (
                    <CardDataProperty
                      label={closet}
                      url="https://res.cloudinary.com/dpj4akaog/image/upload/v1692640578/Indoles/Inmobiliaria/Icons/closet-svgrepo-com_giqcae.svg"
                      value="Closet"
                    />
                  )}
                  {clothingRoom > 0 && (
                    <CardDataProperty
                      label={clothingRoom}
                      url="https://res.cloudinary.com/dpj4akaog/image/upload/v1692640735/Indoles/Inmobiliaria/Icons/hook_w8y1xy.svg"
                      value="Patio de ropas"
                    />
                  )}
                  {coveredParking > 0 && (
                    <CardDataProperty
                      label={coveredParking}
                      url="https://res.cloudinary.com/dpj4akaog/image/upload/v1692641917/Indoles/Inmobiliaria/Icons/coveredParking_herb1c.svg"
                      value="Parqueadero cubierto"
                    />
                  )}
                  {deposit > 0 && (
                    <CardDataProperty
                      label={deposit}
                      url="https://res.cloudinary.com/dpj4akaog/image/upload/v1696122456/Indoles/Inmobiliaria/Icons/deposit-sales-discount-svgrepo-com_uiu7ub.svg"
                      value="Depósito"
                    />
                  )}
                  {diningRoom > 0 && (
                    <CardDataProperty
                      label={diningRoom}
                      url="https://res.cloudinary.com/dpj4akaog/image/upload/v1692642008/Indoles/Inmobiliaria/Icons/diningRoom_lnoktx.svg"
                      value="Comedor"
                    />
                  )}
                  {hall > 0 && (
                    <CardDataProperty
                      label={hall}
                      url="https://res.cloudinary.com/dpj4akaog/image/upload/v1692642132/Indoles/Inmobiliaria/Icons/hall_gtxd9e.svg"
                      value="Hall"
                    />
                  )}
                  {integralKitchen > 0 && (
                    <CardDataProperty
                      label={integralKitchen}
                      url="https://res.cloudinary.com/dpj4akaog/image/upload/v1692642311/Indoles/Inmobiliaria/Icons/integralKitchen_baf6na.svg"
                      value="Cocina integral"
                    />
                  )}
                  {internalGarden > 0 && (
                    <CardDataProperty
                      label={internalGarden}
                      url="https://res.cloudinary.com/dpj4akaog/image/upload/v1692642486/Indoles/Inmobiliaria/Icons/internalGarden_diegdo.svg"
                      value="Jardín interno"
                    />
                  )}
                  {kitchen > 0 && (
                    <CardDataProperty
                      label={kitchen}
                      url="https://res.cloudinary.com/dpj4akaog/image/upload/v1692642706/Indoles/Inmobiliaria/Icons/kitchen_t4gkg7.svg"
                      value="Cocina"
                    />
                  )}
                  {laundryRoom > 0 && (
                    <CardDataProperty
                      label={laundryRoom}
                      url="https://res.cloudinary.com/dpj4akaog/image/upload/v1692642778/Indoles/Inmobiliaria/Icons/laundry_ensqot.svg"
                      value="Espacio lavadora y secadora"
                    />
                  )}
                  {mainBathroom > 0 && (
                    <CardDataProperty
                      label={mainBathroom}
                      url="https://res.cloudinary.com/dpj4akaog/image/upload/v1692642929/Indoles/Inmobiliaria/Icons/bathroom_ta2guh.svg"
                      value="Baño principal"
                    />
                  )}
                  {marbledFloor > 0 && (
                    <CardDataProperty
                      label={marbledFloor}
                      url="https://res.cloudinary.com/dpj4akaog/image/upload/v1692643341/Indoles/Inmobiliaria/Icons/tile_m4u1yy.svg"
                      value="Pisos en mármol"
                    />
                  )}
                  {otherTypeOfFloor !== "" && (
                    <CardDataProperty
                      label={otherTypeOfFloor}
                      url="https://res.cloudinary.com/dpj4akaog/image/upload/v1696122886/Indoles/Inmobiliaria/Icons/floor-plan-svgrepo-com_xl1nd1.svg"
                      value="Otro tipo de piso"
                    />
                  )}
                  {roomBathroom > 0 && (
                    <CardDataProperty
                      label={roomBathroom}
                      url="https://res.cloudinary.com/dpj4akaog/image/upload/v1692642929/Indoles/Inmobiliaria/Icons/bathroom_ta2guh.svg"
                      value="Baño de habitaciones"
                    />
                  )}
                  {serviceRoom > 0 && (
                    <CardDataProperty
                      label={serviceRoom}
                      url="https://res.cloudinary.com/dpj4akaog/image/upload/v1692644211/Indoles/Inmobiliaria/Icons/room_dhzjht.svg"
                      value="Cuarto de servicio"
                    />
                  )}
                  {socialBathroom > 0 && (
                    <CardDataProperty
                      label={socialBathroom}
                      url="https://res.cloudinary.com/dpj4akaog/image/upload/v1692642929/Indoles/Inmobiliaria/Icons/bathroom_ta2guh.svg"
                      value="Baño social"
                    />
                  )}
                  {stairs > 0 && (
                    <CardDataProperty
                      label={stairs}
                      url="https://res.cloudinary.com/dpj4akaog/image/upload/v1692649767/Indoles/Inmobiliaria/Icons/stairs_qdgnkx.svg"
                      value="Escaleras"
                    />
                  )}
                  {scortRoom > 0 && (
                    <CardDataProperty
                      label={scortRoom}
                      url="https://res.cloudinary.com/dpj4akaog/image/upload/v1696122622/Indoles/Inmobiliaria/Icons/room-lamp-svgrepo-com_hpzsk7.svg"
                      value="Sala de escoltas"
                    />
                  )}
                  {serviceBathrooms > 0 && (
                    <CardDataProperty
                      label={serviceBathrooms}
                      url="https://res.cloudinary.com/dpj4akaog/image/upload/v1696123177/Indoles/Inmobiliaria/Icons/bathroom-furniture-hygiene-svgrepo-com_mz5cho.svg"
                      value="Baños de servicio"
                    />
                  )}
                  {studyRoom > 0 && (
                    <CardDataProperty
                      label={studyRoom}
                      url="https://res.cloudinary.com/dpj4akaog/image/upload/v1696123418/Indoles/Inmobiliaria/Icons/studying-desk-and-chair-svgrepo-com_1_jktkoa.svg"
                      value="Estudio"
                    />
                  )}
                  {tiledFloor > 0 && (
                    <CardDataProperty
                      label={tiledFloor}
                      url="https://res.cloudinary.com/dpj4akaog/image/upload/v1692643341/Indoles/Inmobiliaria/Icons/tile_m4u1yy.svg"
                      value="Pisos en baldosa"
                    />
                  )}
                  {terrace > 0 && (
                    <CardDataProperty
                      label={terrace}
                      url="https://res.cloudinary.com/dpj4akaog/image/upload/v1696121219/Indoles/Inmobiliaria/Icons/terrace-svgrepo-com_1_nsd63m.svg"
                      value="Terraza"
                    />
                  )}
                  {tvRoom > 0 && (
                    <CardDataProperty
                      label={tvRoom}
                      url="https://res.cloudinary.com/dpj4akaog/image/upload/v1692649901/Indoles/Inmobiliaria/Icons/tvRoom_vywz59.svg"
                      value="Sala de televisión"
                    />
                  )}
                  {visitorParking > 0 && (
                    <CardDataProperty
                      label={visitorParking}
                      url="https://res.cloudinary.com/dpj4akaog/image/upload/v1692641875/Indoles/Inmobiliaria/Icons/parking_nwc1gg.svg"
                      value="Parqueadero para visitantes"
                    />
                  )}
                  {uncoveredParking > 0 && (
                    <CardDataProperty
                      label={uncoveredParking}
                      url="https://res.cloudinary.com/dpj4akaog/image/upload/v1692649998/Indoles/Inmobiliaria/Icons/parking_s95tkp.svg"
                      value="Parqueadero descubierto"
                    />
                  )}
                  {watsherAndDryer > 0 && (
                    <CardDataProperty
                      label={watsherAndDryer}
                      url="https://res.cloudinary.com/dpj4akaog/image/upload/v1692650132/Indoles/Inmobiliaria/Icons/washer_huuyro.svg"
                      value="Lavadora y secadora"
                    />
                  )}
                  {woodenFloor > 0 && (
                    <CardDataProperty
                      label={woodenFloor}
                      url="https://res.cloudinary.com/dpj4akaog/image/upload/v1692650214/Indoles/Inmobiliaria/Icons/woodenFloor_tmnxi4.svg"
                      value="Pisos en madera"
                    />
                  )}
                </div>
              </section>
            )}

          {/* Property data Section */}
          <section className="flex flex-col gap-3.5 ">
            <h2 className="font-medium text-xl xl:text-2xl">Datos del inmueble</h2>
            <span className="w-full h-1 bg-yellowLogo "></span>
            <div className="grid grid-cols-2 gap-x-10 gap-y-10 my-10 xl:grid-cols-4">
              <div className="flex flex-col px-2 items-center border-r-2 border-yellowLogo sm:flex-row">
                <img src="/icons/property/rule.svg" alt="Rule icon" />
                <h3 className="flex flex-col h-[49px] ml-2 text-xs sm:text-sm">
                  <div className="font-semibold">
                    Precio
                    <span className="mx-1">
                      m<sup className="w-2">2</sup>
                    </span>
                  </div>
                  <span>{pricePerMeter.toLocaleString()}</span>
                </h3>
              </div>
              {privateArea > 0 && (
                <div className="flex flex-col px-2 items-center border-r-2 border-yellowLogo sm:flex-row">
                  <img src="/icons/property/area.svg" width={24} height={24} alt="Char area icon" />
                  <h3 className="flex flex-col h-[49px] ml-2 text-xs sm:text-sm">
                    <div className="font-semibold">
                      Area privada
                      <span className="mx-1">
                        m<sup className="w-2">2</sup>
                      </span>
                    </div>
                    <span>{privateArea.toLocaleString()}</span>
                  </h3>
                </div>
              )}
              {builtArea > 0 && (
                <CardDataProperty
                  label={builtArea}
                  url="/icons/chartArea.svg"
                  value="Area construida m²"
                />
              )}
              {levels > 0 && (
                <CardDataProperty
                  label={levels}
                  url="/icons/property/building.svg"
                  value="Niveles o pisos"
                />
              )}
              {floor > 0 && (
                <CardDataProperty label={floor} url="/icons/property/building.svg" value="Piso" />
              )}
              {stratus > 0 && (
                <CardDataProperty
                  label={stratus}
                  url="/icons/property/stratus.svg"
                  value="Estrato"
                />
              )}
              {antiquity > 0 && (
                <CardDataProperty
                  label={antiquity}
                  url="/icons/clock.svg"
                  value="Antiguedad en años"
                />
              )}
              {amountAdmin > 0 && (
                <CardDataProperty
                  label={amountAdmin}
                  url="/icons/cash.svg"
                  value="Valor administración"
                />
              )}

              {cornerBack && (
                <div className="flex px-2 items-center border-r-2 border-yellowLogo">
                  <img src="/icons/check.svg" width={24} height={24} alt="Clock icon" />
                  <h3 className="flex h-[49px] ml-2 items-center text-sm">{`Esquinero: Si`}</h3>
                </div>
              )}
            </div>
          </section>
          {/* Amenities Section */}
          {amenities.length > 0 && <FeaturesProperty features={amenities} label="Amenidades" />}
          {/* Common zones Section */}
          {commonZones.length > 0 && (
            <FeaturesProperty features={commonZones} label="Zonas comunes" />
          )}
          {/* Of the sector Section */}
          {ofTheSector.length > 0 && <FeaturesProperty features={ofTheSector} label="Sector" />}
          {/* Characteristics Section */}
          {characteristics.length > 0 && (
            <FeaturesProperty features={characteristics} label="Características" />
          )}
          {/* Location Section */}
          <section className="flex flex-col gap-3.5 ">
            <h2 className="font-medium text-xl xl:text-2xl">Ubicación</h2>
            <span className="w-full h-1 bg-yellowLogo" />
            <iframe
              title="Property Location"
              className="rounded"
              width="100%"
              height="455"
              loading="lazy"
              allowFullScreen
              src={`https://www.google.com/maps/embed/v1/place?key=AIzaSyD7valTz6yTj55Lp3HiMyhzktDvp07lllc&q=${
                propertyDetails.address.city +
                propertyDetails.address.neighborhood +
                propertyDetails.address.street
              }`}
            ></iframe>
            <address> {`${street} ${neighborhood} ${city} ${state}`}</address>
          </section>
          {/* Contact Section */}
          <section className="flex justify-center items-center sm:justify-start">
            <article className="flex flex-col w-[280px] h-[310px] items-center justify-center gap-2 bg-grayBgCardInfoContact border border-yellowLogo rounded shadow-sm cursor-pointer">
              <h2 className="font-medium text-base ">¿Te interesa este inmueble ?</h2>
              <div className="relative flex flex-col items-center w-[240px]  rounded-lg">
                <button
                  className="h-10 px-2 w-full flex items-center justify-between rounded text-sm bg-yellowLogo"
                  onClick={() => setIsOpenContact(!isOpenContact)}
                >
                  Contacto
                  {!isOpenContact ? (
                    <img src="/icons/caret.svg" alt="caret icon"></img>
                  ) : (
                    <img
                      src="/icons/caret.svg"
                      alt="caret icon"
                      className="transform rotate-180"
                    ></img>
                  )}
                </button>

                <div
                  className={`bg-gray-800  bg-opacity-10 absolute top-12 flex flex-col gap-2 items-start rounded p-2 w-full transition-opacity duration-300 ${
                    isOpenContact
                      ? "opacity-100 pointer-events-auto"
                      : "opacity-0 pointer-events-none"
                  }`}
                >
                  <div className=" flex gap-2">
                    <img src="/icons/contact/whatsapp.svg" alt="Follow us on whatsapp" />
                    <a href="https://wa.link/vjkynf" target="_blank" rel="noopener noreferrer">
                      3186081940
                    </a>
                  </div>
                  <div className=" flex gap-2">
                    <img
                      width={24}
                      height={24}
                      src="/icons/gmail.svg"
                      alt="Follow us on whatsapp"
                    />
                    <a href="mailto:comercial@indolesltda.com">comercial@indolesltda.com </a>
                  </div>
                </div>
              </div>
            </article>
          </section>
        </div>
      </div>
    </main>
  )
}
