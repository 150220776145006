// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.carousel {
  height: auto;
  width: 90%;
  position: relative;
}

.carouselbox.dragging .item {
  cursor: grab;
  -webkit-user-select: none;
          user-select: none;
}
.switchLeft,
.switchRight {
  text-align: center;
  cursor: pointer;
  z-index: 3;
  transition: background-color 0.3s ease-in-out;
  border-radius: 100%;
  padding: 10px;
}

.switchLeft:hover,
.switchRight:hover {
  background-color: rgba(0, 0, 0, 0.8);
  color: white;
}

.carousel .carouselbox {
  width: auto;
  overflow: hidden;
  white-space: nowrap;
  display: grid;
  text-align: center;
  display: flex;
  align-items: center;
  scroll-behavior: smooth;
}
.carousel .item {
  min-width: 340px;
  max-height: 220px;
  margin: 5px;
  cursor: pointer;
  transition: 0.5s ease-in-out;
  z-index: 2;
}

@media screen and (max-width: 768px) {
  .carousel .item {
    min-width: 300px;
    max-height: 200px;
  }
  .carousel .switchLeft {
    left: -35px;
  }
  .carousel .switchRight {
    right: -35px;
  }
}
`, "",{"version":3,"sources":["webpack://./src/styles/InfiniteCarousel.css"],"names":[],"mappings":"AAAA;EACE,YAAY;EACZ,UAAU;EACV,kBAAkB;AACpB;;AAEA;EACE,YAAY;EACZ,yBAAiB;UAAjB,iBAAiB;AACnB;AACA;;EAEE,kBAAkB;EAClB,eAAe;EACf,UAAU;EACV,6CAA6C;EAC7C,mBAAmB;EACnB,aAAa;AACf;;AAEA;;EAEE,oCAAoC;EACpC,YAAY;AACd;;AAEA;EACE,WAAW;EACX,gBAAgB;EAChB,mBAAmB;EACnB,aAAa;EACb,kBAAkB;EAClB,aAAa;EACb,mBAAmB;EACnB,uBAAuB;AACzB;AACA;EACE,gBAAgB;EAChB,iBAAiB;EACjB,WAAW;EACX,eAAe;EACf,4BAA4B;EAC5B,UAAU;AACZ;;AAEA;EACE;IACE,gBAAgB;IAChB,iBAAiB;EACnB;EACA;IACE,WAAW;EACb;EACA;IACE,YAAY;EACd;AACF","sourcesContent":[".carousel {\n  height: auto;\n  width: 90%;\n  position: relative;\n}\n\n.carouselbox.dragging .item {\n  cursor: grab;\n  user-select: none;\n}\n.switchLeft,\n.switchRight {\n  text-align: center;\n  cursor: pointer;\n  z-index: 3;\n  transition: background-color 0.3s ease-in-out;\n  border-radius: 100%;\n  padding: 10px;\n}\n\n.switchLeft:hover,\n.switchRight:hover {\n  background-color: rgba(0, 0, 0, 0.8);\n  color: white;\n}\n\n.carousel .carouselbox {\n  width: auto;\n  overflow: hidden;\n  white-space: nowrap;\n  display: grid;\n  text-align: center;\n  display: flex;\n  align-items: center;\n  scroll-behavior: smooth;\n}\n.carousel .item {\n  min-width: 340px;\n  max-height: 220px;\n  margin: 5px;\n  cursor: pointer;\n  transition: 0.5s ease-in-out;\n  z-index: 2;\n}\n\n@media screen and (max-width: 768px) {\n  .carousel .item {\n    min-width: 300px;\n    max-height: 200px;\n  }\n  .carousel .switchLeft {\n    left: -35px;\n  }\n  .carousel .switchRight {\n    right: -35px;\n  }\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
