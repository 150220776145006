export default function Consultory() {
  const stagesEngineeringAndConsultory = [
    {
      id: 1,
      title: "Estudios preliminares",
      url: "/icons/paper.svg",
    },
    {
      id: 2,
      title: "Visitas de campo para levantamiento topográficos y estudios de suelos",
      url: "/icons/location.svg",
    },
    {
      id: 3,
      title: "  Evaluación y prefactibilidad de proyecto",
      url: "/icons/checklist.svg",
    },

    {
      id: 4,
      title: "Diseño e ingeniería de detalle",
      url: "/icons/engineering.svg",
    },
    {
      id: 5,
      title: "Permisos ante las entidades públicas",
      url: "/icons/statalEntity.svg",
    },
    {
      id: 6,
      title: "Cotización-Costos y presupuestos",
      url: "/icons/budget.svg",
    },
    {
      id: 7,
      title: "Construcción de la obra civil",
      url: "/icons/bridge.svg",
    },
    {
      id: 8,
      title: "Control y seguimiento",
      url: "/icons/monitoring.svg",
    },
  ]
  return (
    <main>
      <div className="relative">
        <div className="absolute w-full h-full flex flex-col items-center justify-center  gap-3 sm:mb-0 sm:items-start sm:ml-8 xl:ml-[100px]">
          <h1 className="text-3xl text-center text-white font-semibold sm:text-4xl sm:text-left lg:text-[52px] ">
            CONSULTORÍA E INTERVENTORÍA
          </h1>
          <span className="w-[100px] h-1 bg-blueLogo "></span>
          <p className="w-64 text-lg text-justify text-white font-medium sm:w-[430px] xl:w-[430px] xl:text-2xl">
            Te acompañamos en la consultoría e interventoría durante la construcción de tu proyecto
            de vivienda, local comercial e industrial, protegiendo tu inversión
          </p>
        </div>
        <img
          className="object-cover"
          srcSet="https://res.cloudinary.com/dpj4akaog/image/upload/v1689526262/Indoles/Servicios%20Ingenieria/Consultoria/BgConsultoryPage1440_gibk2y.webp 1280w,
            https://res.cloudinary.com/dpj4akaog/image/upload/v1690056783/Indoles/Servicios%20Ingenieria/Consultoria/BgConsultory640_h8lc5x.webp 640w
            "
          sizes="(max-width: 640px) 100vw, 100vw"
          alt="Background Consultory"
          width="100%"
          height={736}
        />
      </div>
      <div className="grid mx-8 my-12 gap-10 sm:grid-cols-2 lg:mx-[100px] lg:my-[100px]">
        <article className="flex flex-col items-center gap-5 sm:items-start xl:w-[466px]">
          <h2 className=" text-2xl font-bold text-center sm:text-left xl:text-3xl">
            Especialistas en gerencia en obras civiles
          </h2>
          <p className="text-sm font-medium text-justify">
            Elaboramos estudios y diseños de proyectos civiles. Además, somos sus aliados en la
            planeación y construcción.
          </p>
          <p className="flex text-base font-bold gap-1">
            Servicio a nivel nacional
            <img
              className="w-6 h-6"
              src="/icons/flagColombia.svg"
              alt="El pais Colombia"
              width="100%"
              height="100%"
            />
          </p>
          <a
            className="flex w-60 h-14 justify-center items-center  rounded-[2px] bg-blueLogo font-bold text-white"
            href="/Contáctanos"
          >
            CONTACTANOS
          </a>
        </article>
        <img
          className=" rounded-sm lg:rounded"
          srcSet="https://res.cloudinary.com/dpj4akaog/image/upload/v1689527657/Indoles/Servicios%20Ingenieria/Consultoria/EspecialistAtGerency_aw3kcf.webp 1440w"
          alt="Specialists Engineers"
          width="100%"
          height="100%"
        />
      </div>
      {/* Section Process description */}
      <div className="mx-8 lg:mx-[100px] lg:my-[100px]">
        <h2 className="font-bold text-center text-2xl md:text-left">
          Aportamos valor en cada etapa
        </h2>
        <div className="grid place-items-center xl:grid-cols-2 gap-10 my-6 ml-0 ">
          <div className="flex flex-col gap-6 sm:flex-row xl:w-[585px] ">
            <div className="w-[260px] h-[176px]">
              <img
                className="rounded-sm md:rounded"
                srcSet="https://res.cloudinary.com/dpj4akaog/image/upload/v1689538740/Indoles/Servicios%20Ingenieria/Consultoria/StudiesAndDesign_vjvtrz.webp 1440w"
                alt="Design and studies"
                width="100%"
                height="100%"
              />
            </div>
            <article className="flex flex-col w-64 gap-5 md:w-[301px]">
              <h3 className="font-semibold text-base">Estudios y Diseños</h3>
              <p className="text-justify text-sm text-grayTextConsultory">
                Aportamos nuestra experiencia y especialización para llevar a cabo los estudios y
                diseños de sus proyectos civiles del sector vivienda, comercial, industrial e
                infraestructura.
              </p>
            </article>
          </div>
          <div className="flex flex-col gap-6 sm:flex-row xl:w-[585px]">
            <div className="w-[260px] h-[176px]">
              <img
                className="rounded-sm md:rounded "
                srcSet="https://res.cloudinary.com/dpj4akaog/image/upload/v1690056535/Indoles/Servicios%20Ingenieria/Consultoria/Planning1440_kygtrj.webp 1440w"
                alt="Planning"
                width="100%"
                height="100%"
              />
            </div>
            <article className="flex flex-col w-64 gap-5 lg:w-[301px]">
              <h3 className="font-semibold text-base">Planeación</h3>
              <p className="text-justify text-sm text-grayTextConsultory">
                Pensamos y dedicamos nuestro tiempo para planificar sus obras civiles con la mejor
                eficiencia en el costo beneficio de sus inversiones.
              </p>
            </article>
          </div>
          <div className="flex flex-col gap-6 sm:flex-row xl:w-[585px]">
            <div className="w-[260px] h-[176px]">
              <img
                className="rounded-sm md:rounded"
                srcSet="https://res.cloudinary.com/dpj4akaog/image/upload/v1690056233/Indoles/Servicios%20Ingenieria/Consultoria/Construction1440_lyzczp_mxhfag.webp 1440w"
                alt="Plannig"
                width="100%"
                height="100%"
              />
            </div>
            <article className="flex flex-col w-64 gap-5 lg:w-[301px]">
              <h3 className="font-semibold text-base">Ejecución</h3>
              <p className="text-justify text-sm text-grayTextConsultory">
                Aportamos nuestra experiencia y especialización para llevar a cabo los estudios y
                diseños de sus proyectos civiles del sector vivienda, comercial, industrial e
                infraestructura.
              </p>
            </article>
          </div>
        </div>
      </div>
      {/* Section Stages */}
      <div className="space-y-10 mx-8 my-12 items-center lg:mx-[100px] lg:my-[100px] ">
        <div className="flex flex-col items-center gap-5">
          <h2 className="font-bold text-center text-2xl sm:text-left md:text-3xl">
            Ingeniería y Consultoría
          </h2>
          <p className="text-justify text-sm md:text-base">
            Indoles te apoya en la sigueintes etapas del proyecto
          </p>
        </div>
        <div className="grid sm:grid-cols-2 gap-y-6 lg:grid-cols-4">
          {stagesEngineeringAndConsultory.map((stage) => (
            <div key={stage.id} className="flex flex-col items-center gap-2 text-center">
              <div className="flex w-24 h-24 justify-center items-center rounded-full border border-black">
                <img width={24} height={24} src={stage.url} alt={stage.title} />
              </div>
              <h3>{stage.title}</h3>
            </div>
          ))}
        </div>
      </div>
      {/* Section Aliados */}
      <div className="grid sm:grid-cols-2 gap-10 mx-8 my-12 xl:mx-[100px] xl:my-[100px]">
        <img
          className=" rounded-sm lg:rounded order-2 sm:order-1"
          srcSet="https://res.cloudinary.com/dpj4akaog/image/upload/v1689562202/Indoles/Servicios%20Ingenieria/Consultoria/WorkTeamAtExito_buuizn.webp 1400w,
          https://res.cloudinary.com/dpj4akaog/image/upload/w_768/v1689562202/Indoles/Servicios%20Ingenieria/Consultoria/WorkTeamAtExito_buuizn.webp  768w
          "
          loading="lazy"
          width="100%"
          height="100%"
          alt="Work Team At Exito"
        />
        <div className="flex order-1 justify-center sm:order-2 ">
          <article className="flex flex-col gap-5 xl:w-[400px]">
            <h2 className="font-bold text-2xl sm:text-3xl">Nuestros Aliados</h2>
            <p className="font-medium text-sm text-justify">
              Satisfacción de nuestros clientes Petróleos Sudamericanos, Enel, y éxito en los
              servicios prestados en sus proyectos en donde cumplimos las metas y objetivos
              trazados.
            </p>
          </article>
        </div>
      </div>
      {/* Section partners logos  */}
      <div className="flex flex-col justify-center items-center  mx-8 my-12 gap-10 xl:mx-[100px] xl:my-[100px]">
        <h2 className="font-bold text-center text-2xl sm:text-left md:text-3xl  ">
          Ellos confían en INDOLES
        </h2>
        <div className="flex flex-col items-center sm:flex-row gap-6">
          <img src="/partners/enel.png" alt="Enterprise Enel" width={240} height={85} />
          <img
            src="/partners/petroleosSudamericanos.webp"
            alt="Enterprise Petroleos Sudamericanos"
            width={240}
            height={112}
          />
          <img src="/partners/exito.png" alt="Enterprise Extio" width={92} height={92} />
        </div>
      </div>
    </main>
  )
}
