/** @format */

import { useState } from "react"

export default function Dropdown({ services }) {
  const [open, setOpen] = useState(false)
  return (
    <div className="relative w-full mx-1 z-10">
      <button
        className="flex w-full items-center text-sm font-medium"
        onClick={() => setOpen(!open)}
        aria-expanded={open}
        aria-haspopup="true"
      >
        Servicios
        <img
          className={`${open ? "rotate-180" : ""}`}
          src="/icons/caret.svg"
          width={24}
          height={24}
          alt="Caret icon"
        />
        <div
          className={`absolute top-12 right-0  w-72 bg-white rounded-lg border-2 border-gray-200 transition-opacity shadow-xl duration-300 ${
            open ? "opacity-100" : "opacity-0 pointer-events-none"
          }`}
        >
          <ul className="flex flex-col gap-2">
            {services.map((service) => (
              <li key={service.id}>
                <div className="py-2 rounded-sm hover:bg-amber-200 hover:bg-opacity-30">
                  <div className="flex w-full justify-center items-center">
                    <a className="w-full" href={service.url}>
                      {service.name}
                    </a>
                  </div>
                </div>
              </li>
            ))}
          </ul>
        </div>
      </button>
    </div>
  )
}
