import React from "react"

export default function ButtonPage() {
  return (
    <a
      className="flex w-[247px] h-[60px] justify-center items-center rounded-[6px] font-bold bg-blueLogo text-white hover:text-blueLogo hover:bg-opacity-0 hover:border-2 hover:border-blueLogo transition-all duration-300 ease-in"
      href="/Licencia-de-construcción/cotización"
    >
      INICIA EL PROCESO
    </a>
  )
}
