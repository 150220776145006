export default function EstructuralDesign() {
  return (
    <main className="container">
      <div
        className="flex flex-col w-full h-[500px] justify-end items-center bg-center bg-cover sm:justify-center sm:items-start sm:gap-5 lg:h-[600px] "
        style={{
          backgroundImage: `url('https://res.cloudinary.com/dpj4akaog/image/upload/v1689301073/Indoles/Servicios%20Ingenieria/Dise%C3%B1o%20Estructural/BgEstructuralDesign_dngkib.webp')`,
        }}
      >
        <div className="flex flex-col w-[296px] items-center mb-14 sm:w-[430px] sm:mb-0 sm:items-start sm:ml-[100px] gap-3">
          <h1 className="text-[32px] text-white font-semibold xl:text-[42px]">
            Diseño Estructural
          </h1>
          <span className="w-[100px] h-1 bg-blueLogo "></span>
          <p className=" text-base text-justify text-white  xl:text-2xl font-medium ">
            Diseñamos estructuras para tu vivienda, tus proyectos comerciales e
            industriales cumpliendo con tus sueños y metas.
          </p>
        </div>
      </div>
      <div
        className="flex w-full justify-center bg-grayBgSlider 
        "
      >
        <div className="grid place-items-center my-12 gap-10 sm:grid-cols-2 lg:my-[100px] ">
          <section className="flex flex-col items-center w-[256px] order-2 space-y-6 sm:order-1 md:w-[300px] xl:w-[350px]">
            <article className="flex flex-col items-center gap-5 sm:items-start ">
              <h3 className="font-semibold text-2xl text-center text-white sm:text-left">
                Diseño Estructural
              </h3>
              <p className="text-grayTextEstructuralDesignPage text-sm text-justify font-medium">
                A través de un grupo de ingenieros civiles especializados en el
                diseño estructural y con experiencia se analizan las diferentes
                características de funcionalidad, seguridad y economía de los
                proyectos civiles.
              </p>
            </article>
            <article className="flex flex-col items-center gap-5 sm:items-start">
              <h3 className="font-semibold text-2xl text-center text-white sm:text-left">
                Utilización de Software estructural
              </h3>
              <p className="text-grayTextEstructuralDesignPage text-sm text-justify font-medium">
                A través del software para diseño estructural modelamos y
                analizamos las estructuras para el cumplimiento de la
                normatividad y requerimientos de las entidades encargadas de los
                permisos de construcción.
              </p>
            </article>
          </section>
          <div className="flex flex-col w-[296px] my-12 items-center order-1 xl:w-[476px] gap-5 ">
            <h2 className="font-bold text-2xl text-center text-white lg:text-3xl">
              Soluciones Integrales
            </h2>
            <p className="text-grayTextEstructuralDesignPage text-sm text-[18px] md:text-sm text-justify font-medium">
              En cada estructura diseñada por nosotros ofrecemos la mejor
              experiencia con seguridad, economía, estabilidad y eficiencia de
              los recursos cumpliendo los estandares de calidad.
            </p>
            <img
              className="rounded"
              src="https://res.cloudinary.com/dpj4akaog/image/upload/v1689348774/Indoles/Servicios%20Ingenieria/Dise%C3%B1o%20Estructural/Structure_avixbe.webp"
              alt="Structure"
              width="100%"
              height="100%"
            />
          </div>
        </div>
      </div>
      <div className="flex justify-center my-12 lg:my-[100px]">
        <div className="grid grid-cols-2 mx-8 md:grid-cols-3 gap-5 ">
          <div className="flex flex-col text-center items-center gap-3 xl:w-[350px]">
            <img
              className="rounded shadow-xl w-full h-auto"
              src="https://res.cloudinary.com/dpj4akaog/image/upload/v1689353230/Indoles/Servicios%20Ingenieria/Dise%C3%B1o%20Estructural/Estructures_Metalics_dzz7kz.webp"
              alt="Metallic structure"
              width="100%"
              height="100%"
            />
            <h3 className="text-base font-medium ">
              Diseño de Estructuras metalicas
            </h3>
          </div>
          <div className="flex flex-col text-center items-center gap-3 xl:w-[350px]">
            <img
              className="rounded shadow-xl w-full h-auto"
              src="https://res.cloudinary.com/dpj4akaog/image/upload/v1689353230/Indoles/Servicios%20Ingenieria/Dise%C3%B1o%20Estructural/Building_em5zlz.webp"
              alt="Design for Houses, malls and factories"
              width="100%"
              height="100%"
            />
            <h3 className="text-base font-medium ">
              Diseño para vivienda, centros comerciales e industriales
            </h3>
          </div>
          <div className="flex flex-col text-center items-center gap-3 xl:w-[350px]">
            <img
              className="w-full h-auto rounded shadow-xl"
              src="https://res.cloudinary.com/dpj4akaog/image/upload/v1689353230/Indoles/Servicios%20Ingenieria/Dise%C3%B1o%20Estructural/Modelin_flrpdy.webp"
              alt="Vulnerability study"
              width="100%"
              height="100%"
            />
            <h3 className="text-base font-medium ">
              Estudio de vulnerabilidad sismica y reforzamiento estructural
            </h3>
          </div>
          <div className="flex flex-col text-center items-center gap-3 xl:w-[350px]">
            <img
              className="rounded shadow-xl"
              src="https://res.cloudinary.com/dpj4akaog/image/upload/v1689353230/Indoles/Servicios%20Ingenieria/Dise%C3%B1o%20Estructural/Bridge_lsvoyu.webp"
              alt="Bridge"
              width="100%"
              height="100%"
            />
            <h3 className="text-base font-medium ">
              Diseño estructural de puentes
            </h3>
          </div>
          <div className="flex flex-col text-center items-center gap-3 xl:w-[350px]">
            <img
              className="rounded shadow-xl"
              src="https://res.cloudinary.com/dpj4akaog/image/upload/v1689353231/Indoles/Servicios%20Ingenieria/Dise%C3%B1o%20Estructural/Outdoors_qgjgh4.webp"
              alt="Outdoor"
              width="100%"
              height="100%"
            />
            <h3 className="text-base font-medium ">
              Diseño de cimentaciones y muros de contención
            </h3>
          </div>
          <div className="flex flex-col text-center items-center gap-3 xl:w-[350px]">
            <img
              className="rounded shadow-xl"
              src="https://res.cloudinary.com/dpj4akaog/image/upload/v1689353230/Indoles/Servicios%20Ingenieria/Dise%C3%B1o%20Estructural/Pools_dfyphb.webp"
              alt="Swing pool "
              width="100%"
              height="100%"
            />
            <h3 className="text-base font-medium ">
              Diseño de tanques de almacenamineto, piscinas.
            </h3>
          </div>
          <div className="flex flex-col text-center items-center gap-3 xl:w-[350px]">
            <img
              className="rounded shadow-xl"
              src="https://res.cloudinary.com/dpj4akaog/image/upload/v1689353230/Indoles/Servicios%20Ingenieria/Dise%C3%B1o%20Estructural/Factory_iswup1.webp"
              alt="Factory"
              width="100%"
              height="100%"
            />
            <h3 className="text-base font-medium ">
              Diseño de Estructuras Especiales
            </h3>
          </div>
          <div className="flex flex-col text-center items-center gap-3 xl:w-[350px]">
            <img
              className="rounded shadow-xl"
              src="https://res.cloudinary.com/dpj4akaog/image/upload/v1689353231/Indoles/Servicios%20Ingenieria/Dise%C3%B1o%20Estructural/Reviewer_xgwvhu.webp"
              alt="Supevision technique"
              width="100%"
              height="100%"
            />
            <h3 className="text-base font-medium ">
              Revisión de diseños y supervisión técnica
            </h3>
          </div>
          <div className="flex flex-col text-center items-center gap-3 xl:w-[350px]">
            <img
              className="rounded shadow-xl"
              src="https://res.cloudinary.com/dpj4akaog/image/upload/v1689353230/Indoles/Servicios%20Ingenieria/Dise%C3%B1o%20Estructural/Estructural_Peritaje_fsfwhy.webp"
              alt="Structural expertise"
              width="100%"
              height="100%"
            />
            <h3 className="text-base font-medium ">Peritaje estructural</h3>
          </div>
        </div>
      </div>
      <div className="grid place-items-center my-12 mx-8 gap-10 md:grid-cols-2  lg:mx-[100px] lg:my-[100px]  ">
        <article className="flex flex-col w-[296px] items-center gap-5 xl:w-[400px] ">
          <h2 className="font-bold text-2xl md:text-3xl">
            Expertos en el área
          </h2>
          <p className="text-sm text-justify">
            Somos ingenieros civiles con maestría y especialistas en el diseño
            estructural con +20 años de experiencia en el sector público,
            privado
          </p>
        </article>
        <div className="flex justify-center rounded bg-blueBgFooter shadow-zinc-700 shadow-lg xl:w-[600px] ">
          <div className="px-6 my-6">
            <img
              className="rounded"
              srcSet="https://res.cloudinary.com/dpj4akaog/image/upload/v1689519542/Indoles/Servicios%20Ingenieria/Dise%C3%B1o%20Estructural/groupEngineers1440_gcaapp.webp 1440w,
              https://res.cloudinary.com/dpj4akaog/image/upload/v1689520048/Indoles/Servicios%20Ingenieria/Dise%C3%B1o%20Estructural/groupEngineers768_fz2rnv.webp
              "
              width="100%"
              height="100%"
              alt="Group of Experts"
            />
          </div>
        </div>
      </div>
    </main>
  )
}
