/** @format */

import { BrowserRouter as Router, Routes, Route, Navigate } from "react-router-dom"
import { ToastContainer } from "react-toastify"
import "./App.css"
import AboutUs from "./pages/AboutUs"
import Home from "./pages/Home"
import BuildingPermit from "./pages/BuildingPermit"
import BudgetAndCosts from "./pages/BudgetAndCosts"
import Consultory from "./pages/Consultory"
import ContactUs from "./pages/ContactUs"
import EstructuralDesign from "./pages/EstructuralDesign"
import Layout from "./pages/Layout"
import RealState from "./pages/RealState"
import Property from "./pages/Property"
import PropertyDetails from "./pages/PropertyDetails"
import Quotes from "./pages/Quotes"
import PrivacyPolice from "./pages/PrivacyPolice"
import Job from "./pages/Job"
function App() {
  return (
    <div>
      <Router>
        <Routes>
          <Route element={<Layout />}>
            <Route exact path="/Nosotros" element={<AboutUs />} />
            <Route exact path="/" element={<Home />} />
            <Route exact path="/Licencias-de-construcción" element={<BuildingPermit />} />
            <Route exact path="/Costos-y-presupuestos" element={<BudgetAndCosts />} />
            <Route exact path="/Contáctanos" element={<ContactUs />} />
            <Route exact path="/Consultoría" element={<Consultory />} />
            <Route exact path="/oferta-laboral" element={<Job />} />
            <Route exact path="/Licencia-de-construcción/cotización" element={<Quotes />} />
            <Route
              exact
              path="/política-de-protección-de-datos-personales"
              element={<PrivacyPolice />}
            />
            <Route exact path="/Diseño-estructural" element={<EstructuralDesign />} />
            <Route exact path="/Inmobiliaria" element={<RealState />} />
            <Route exact path="/Inmobiliaria/Inmueble" element={<Property />} />
            <Route exact path="/Inmobiliaria/Inmueble/Detalles" element={<PropertyDetails />} />
          </Route>
          <Route path="*" element={<Navigate to="/" />} />
        </Routes>
      </Router>
      <ToastContainer />
    </div>
  )
}

export default App
