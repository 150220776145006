import React from "react"

export default function FilterSearch({
  value,
  handleFilter,
  typeFilter,
  icon,
}) {
  return (
    <div
      className={`flex items-center py-4 px-2 gap-1 rounded-md border border-yellowLogo font-medium text-base  ${
        typeFilter === value ? "bg-yellowLogo font-medium text-white" : ""
      }`}
      onClick={() => {
        handleFilter(value)
      }}
    >
      {icon}
      {value}
    </div>
  )
}
