/** @format */

import { useState } from "react"
import { useLocation, useNavigate } from "react-router-dom"
import ReactPaginate from "react-paginate"
import LoadingState from "../components/LoadingState"
import UseGetFilteredProperty from "../hooks/UseGetFilteredProperty"
export default function Property() {
  const location = useLocation()
  const navigate = useNavigate()
  const queryParams = new URLSearchParams(location.search)
  const { city, type, offert } = Object.fromEntries(queryParams.entries())
  const { propertiesFiltered, loading } = UseGetFilteredProperty({
    city,
    offert,
    type,
  })
  const [currentPage, setCurrentPage] = useState(1)
  const itemsPerPage = window.innerWidth > 1024 ? 12 : window.innerWidth > 768 ? 8 : 2
  const totalPages = Math.ceil(propertiesFiltered.length / itemsPerPage)
  const startIndedx = (currentPage - 1) * itemsPerPage
  const handleClickOpenImage = (id) => {
    navigate(`/Inmobiliaria/Inmueble/Detalles?id=${id}`)
  }
  if (loading) return <LoadingState />
  return (
    <main>
      {/*Filtered properties*/}
      <div className="grid place-items-center my-10 mx-10 gap-4 xl:my-[100px] md:grid-cols-3 xl:grid-cols-4">
        {propertiesFiltered.length > 0 ? (
          propertiesFiltered.slice(startIndedx, startIndedx + itemsPerPage).map((property) => (
            <section
              className="item group relative rounded overflow-hidden cursor-pointer shadow-sm "
              draggable="false"
              key={property._id}
            >
              <img
                src={property.media.imageCard.url}
                alt="Card Propertie"
                width={340}
                height={227}
              />
              <div className="absolute left-4 top-4 px-4 py-1 rounded-sm bg-grayBgSlider bg-opacity-80 text-yellowLogo font-medium ">
                {property.usage?.find((usage) => usage === offert) || property.usage[0]}
              </div>
              <div className="absolute left-1/2 bottom-4 transform -translate-x-1/2 px-2 flex flex-col items-start w-11/12 rounded-sm bg-grayBgSlider bg-opacity-80 ">
                <span className="text-yellowLogo font-medium">{`${property.propertyType} en ${property.address.neighborhood}`}</span>
                <div className="flex w-full justify-between font-medium">
                  <span className="text-white ">{`${property.address.city} ${property.address.state}`}</span>
                  <span className="text-yellowLogo">{`$ ${property.data.price.toLocaleString()}`}</span>
                </div>
              </div>
              <div className="absolute inset-0 flex flex-col items-center justify-center translate-y-[60%] group-hover:translate-y-0 group-hover:bg-black group-hover:bg-opacity-30 transition-all ease-in  ">
                <img
                  src="/icons/play.svg"
                  alt="Play button"
                  width={48}
                  height={48}
                  onClick={() => handleClickOpenImage(property._id)}
                />
              </div>
            </section>
          ))
        ) : (
          <p className="col-span-4 justify-items-center h-screen">No está disponible</p>
        )}
      </div>
      {/*Pagination*/}
      {propertiesFiltered?.length > 0 && (
        <ReactPaginate
          className="w-full mb-10 flex justify-center items-center xl:py-[100px]"
          previousLabel={
            <svg
              xmlns="http://www.w3.org/2000/svg"
              fill="none"
              viewBox="0 0 24 24"
              strokeWidth={1.5}
              stroke="currentColor"
              className="w-6 h-6"
            >
              <path strokeLinecap="round" strokeLinejoin="round" d="M15.75 19.5L8.25 12l7.5-7.5" />
            </svg>
          }
          nextLabel={
            <svg
              xmlns="http://www.w3.org/2000/svg"
              fill="none"
              viewBox="0 0 24 24"
              strokeWidth={1.5}
              stroke="currentColor"
              className="w-6 h-6"
            >
              <path strokeLinecap="round" strokeLinejoin="round" d="M8.25 4.5l7.5 7.5-7.5 7.5" />
            </svg>
          }
          breakLabel={"..."}
          pageCount={totalPages}
          pageClassName="inline-block mx-1 bg-blueLogo text-white px-2 rounded"
          marginPagesDisplayed={2}
          pageRangeDisplayed={5}
          onPageChange={(selectedPage) => setCurrentPage(selectedPage.selected + 1)}
          containerClassName={"pagination"}
          activeClassName={"active"}
        />
      )}
    </main>
  )
}
