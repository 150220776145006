/** @format */
import { useState, useRef } from "react"
import { useNavigate } from "react-router-dom"
import UseGetProperties from "../hooks/UseGetProperties"
import { toast } from "react-toastify"
import "react-toastify/dist/ReactToastify.css"
import Carouselnfinite from "../components/Carousels/Carouselnfinite"
import DropdownOffertFilter from "../components/Dropdowns Mobile/DropdownOffertFilter"
import DropdownTypeFilter from "../components/Dropdowns Mobile/DropdownTypeFilter"
import LoadingState from "../components/LoadingState"
import { SearchBar } from "../components/SearchBar"

export default function RealState() {
  const inputRef = useRef(null)
  const [offert, setOffert] = useState("")
  const [type, setType] = useState("")
  const { loading, properties } = UseGetProperties()
  const navigate = useNavigate()

  const handleSubmit = async (event) => {
    event.preventDefault()
    const inputValueCity = inputRef.current.value
    if (inputValueCity === "") {
      toast("Ingresa un valor para el campo ciudad ")
      return
    }
    navigate(`/Inmobiliaria/Inmueble?city=${inputValueCity}&type=${type}&offert=${offert}`)
  }
  const handleValuePropertyType = (value) => {
    setType(value)
  }
  const handleValueOffertType = (value) => {
    setOffert(value)
  }

  return (
    <main>
      {/* Hero section */}
      <div className="relative z-10">
        <picture>
          <source
            srcSet="https://res.cloudinary.com/dpj4akaog/image/upload/v1694452889/Indoles/Inmobiliaria/bgInmobiliaria_w1280_qm0t11_1_nroobv.webp"
            media="(min-width: 640px)"
            sizes="(min-width: 640px) 100vw, 1440px"
          />
          <source
            srcSet="https://res.cloudinary.com/dpj4akaog/image/upload/v1694453537/Indoles/Inmobiliaria/IMG_9016_cwj8mr.webp"
            media="(max-width: 640px)"
            sizes="(max-width: 640px) 100vw, 640px"
          />
          <img
            src="https://res.cloudinary.com/dpj4akaog/image/upload/v1694452889/Indoles/Inmobiliaria/bgInmobiliaria_w1280_qm0t11_1_nroobv.webp"
            alt="Background real state"
            width="100%"
            height={600}
            className="object-cover"
          />
        </picture>
        {/*Search bar*/}
        <div className="absolute top-1/2 left-1/2 transform -translate-x-1/2 -translate-y-1/2 flex flex-col items-center">
          <h1 className="text-center text-3xl text-white font-bold lg:text-[42px] xl:text-[52px] mx-2">
            ENCUENTRA UN LUGAR PARA TI
          </h1>
          <SearchBar
            handleSubmit={handleSubmit}
            inputRef={inputRef}
            handleValueOffertType={handleValueOffertType}
            handleValuePropertyType={handleValuePropertyType}
            type={type}
            offert={offert}
          />
        </div>
      </div>
      <div className="relative my-12 px-10 md:my-[100px]">
        {/* Dropdowns to select offert and type for mobile */}
        <div className="flex justify-between w-full sm:hidden">
          <DropdownTypeFilter handleValuePropertyType={handleValuePropertyType} type={type} />
          <DropdownOffertFilter handleValueOffertType={handleValueOffertType} offert={offert} />
        </div>
        {/* Principal content */}
        <div className="flex flex-col items-center mt-10 xl:mt-5">
          <h2 className="font-semibold text-2xl text-center xl:text-3xl">Inmuebles destacados</h2>
          <h3 className="flex flex-col items-center gap-1 md:text-lg">
            Un nuevo lugar para ti
            <span className="w-20 h-1 bg-yellowLogo"></span>
          </h3>
        </div>
      </div>
      {loading ? (
        <LoadingState />
      ) : (
        <div className="flex w-full  px-5 my-12 justify-center ">
          <Carouselnfinite properties={properties} />
        </div>
      )}
    </main>
  )
}
