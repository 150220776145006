/** @format */
import NavBar from "../components/Navigation/NavBar"
import UnderSide from "../components/UnderSide"
import { Outlet } from "react-router-dom"
export default function Layout() {
  return (
    <div className="container max-w-[1600px]">
      <NavBar />
      <Outlet />
      <UnderSide />
    </div>
  )
}
