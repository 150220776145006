/** @format */

import { useState } from "react"
import Dropdown from "./Dropdown"
export default function NavBar() {
  const [toggle, setToggle] = useState(false)
  const [toggleServices, setToggleServices] = useState(false)
  const links = [
    { id: 1, name: "Home", url: "/" },
    { id: 2, name: "Inmobiliaria", url: "/Inmobiliaria" },
    { id: 3, name: "Contáctanos", url: "/Contáctanos" },
    { id: 4, name: "Nosotros", url: "/Nosotros" },
  ]
  const linksServices = [
    { id: 1, name: "Consultoría", url: "/Consultoría" },
    { id: 2, name: "Diseño estructural", url: "/Diseño-estructural" },
    { id: 3, name: "Licencias de construcción", url: "/Licencias-de-construcción" },
    { id: 4, name: "Costos y presupuestos", url: "/Costos-y-presupuestos" },
  ]
  const closeMobileMenus = () => {
    setToggle(false)
  }
  return (
    <header className="sticky top-0 w-full shadow-lg bg-white z-20 ">
      <nav className="mx-8 md:flex md:justify-between">
        {/*Desktop navbar*/}
        <div className="flex justify-between items-center py-2 ">
          <a href="/">
            <img
              className="lg:w-28 lg:h-20"
              srcSet="https://res.cloudinary.com/dpj4akaog/image/upload/v1689972486/Indoles/LogoIndoles_a3ycwq.webp 1440w"
              src="/LogoIndoles.jpg"
              alt="Logo Indoles LTDA"
              width={80}
              height={50}
            />
          </a>
          <div className="text-2xl cursor-pointer md:hidden">
            <svg
              onClick={() => setToggle(!toggle)}
              viewBox="0 0 1024 1024"
              fill="currentColor"
              height="1em"
              width="1em"
            >
              <path d="M904 160H120c-4.4 0-8 3.6-8 8v64c0 4.4 3.6 8 8 8h784c4.4 0 8-3.6 8-8v-64c0-4.4-3.6-8-8-8zm0 624H120c-4.4 0-8 3.6-8 8v64c0 4.4 3.6 8 8 8h784c4.4 0 8-3.6 8-8v-64c0-4.4-3.6-8-8-8zm0-312H120c-4.4 0-8 3.6-8 8v64c0 4.4 3.6 8 8 8h784c4.4 0 8-3.6 8-8v-64c0-4.4-3.6-8-8-8z" />
            </svg>
          </div>
        </div>
        <div className="hidden items-center md:flex md:gap-2">
          <ul className="cursor-pointer md:flex md:gap-2 lg:gap-4">
            {links.map((link) => (
              <li className="group relative flex justify-center items-center lg:w-24" key={link.id}>
                <a href={link.url}>{link.name}</a>
                <span className="absolute left-0 bottom-0 w-0 h-0.5 bg-greenLogo transform origin-left transition-all duration-500 ease-in-out group-hover:w-full"></span>
              </li>
            ))}
            <li className="flex justify-center items-center lg:w-24">
              <Dropdown services={linksServices} />
            </li>
          </ul>
        </div>
        {/*Mobile navbar*/}
        <div
          className={`fixed top-0 left-0 w-screen h-screen bg-white z-50 transition-transform duration-700 ${
            toggle ? "translate-x-0" : "-translate-x-full"
          } md:hidden`}
        >
          <div
            className="flex w-full items-center justify-between border border-b-grayBorderNavigation"
            onClick={() => setToggleServices(!toggle)}
          >
            <button className="ml-1 px-2 py-2">
              <svg
                xmlns="http://www.w3.org/2000/svg"
                height="1em"
                width="1em"
                viewBox="0 0 448 512"
                fill="#464646"
              >
                <path d="M9.4 233.4c-12.5 12.5-12.5 32.8 0 45.3l160 160c12.5 12.5 32.8 12.5 45.3 0s12.5-32.8 0-45.3L109.2 288 416 288c17.7 0 32-14.3 32-32s-14.3-32-32-32l-306.7 0L214.6 118.6c12.5-12.5 12.5-32.8 0-45.3s-32.8-12.5-45.3 0l-160 160z" />
              </svg>
            </button>
            <div className="px-2 py-2 cursor-pointer " onClick={() => closeMobileMenus()}>
              <svg viewBox="0 0 512 512" fill="currentColor" height="24" width="24">
                <path
                  fill="none"
                  stroke="currentColor"
                  strokelinaecap="round"
                  strokeLinejoin="round"
                  strokeWidth={32}
                  d="M368 368L144 144M368 144L144 368"
                />
              </svg>
            </div>
          </div>
          <div className="relative px-5 py-4">
            <ul className="flex flex-col gap-4">
              {links.map((link) => (
                <li className="font-medium text-sm" key={link.id}>
                  <a className="" href={link.url}>
                    {link.name}
                  </a>
                </li>
              ))}

              <li className="font-medium text-sm">
                <div
                  className="flex justify-between items-center"
                  onClick={() => setToggleServices(!toggleServices)}
                >
                  Services
                  <svg xmlns="http://www.w3.org/2000/svg" height="1em" viewBox="0 0 448 512">
                    <path d="M438.6 278.6c12.5-12.5 12.5-32.8 0-45.3l-160-160c-12.5-12.5-32.8-12.5-45.3 0s-12.5 32.8 0 45.3L338.8 224 32 224c-17.7 0-32 14.3-32 32s14.3 32 32 32l306.7 0L233.4 393.4c-12.5 12.5-12.5 32.8 0 45.3s32.8 12.5 45.3 0l160-160z" />
                  </svg>
                </div>
                {
                  <div
                    className={`fixed top-11 left-0 w-screen h-screen bg-white z-50  transition-transform duration-700 ${
                      toggleServices ? " translate-x-0" : "translate-x-full hidden "
                    }`}
                  >
                    <ul className="flex flex-col px-5 py-4 gap-4 ">
                      {linksServices.map((link) => (
                        <li className="font-medium text-sm" key={link.id}>
                          <a className="" href={link.url}>
                            {link.name}
                          </a>
                        </li>
                      ))}
                    </ul>
                  </div>
                }
              </li>
            </ul>
          </div>
        </div>
      </nav>
      {}
    </header>
  )
}
