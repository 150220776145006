/** @format */
import { useRef } from "react"
import "../../styles/Carousel.css"
export default function Carousel({ media }) {
  const carouselRef = useRef(null)
  const cardRef = useRef(null)
  const handleScroll = (direction) => {
    if (direction === "left") {
      carouselRef.current.scrollLeft -= cardRef.current.offsetWidth + 10
    } else {
      carouselRef.current.scrollLeft += cardRef.current.offsetWidth + 10
    }
  }
  const slides = media.video.url
    ? [media.video.url, ...media.imageSlider.map((image) => image.url)]
    : media.imageSlider.map((image) => image.url)
  return (
    <div className="slider">
      <div className="sliderbox" ref={carouselRef}>
        {slides.map((url, index) => (
          <div
            className="item cursor-pointer shadow-sm"
            draggable="false"
            ref={cardRef}
            key={index}
          >
            {url === media.video.url ? (
              <video
                className="rounded h-[187px]"
                controls
                width={340}
                height={227}
                src={url}
                alt={index || "Video"}
              />
            ) : (
              <img
                className="rounded"
                src={url}
                alt={index || "Image"}
                width={340}
                height={187}
              />
            )}
          </div>
        ))}
      </div>
      <i className="switchLeft" onClick={() => handleScroll("left")}>
        <svg
          xmlns="http://www.w3.org/2000/svg"
          fill="none"
          viewBox="0 0 24 24"
          strokeWidth={1.5}
          stroke="currentColor"
          className="w-4 h-4 xl:w-6 xl:h-6"
        >
          <path
            strokeLinecap="round"
            strokeLinejoin="round"
            d="M15.75 19.5L8.25 12l7.5-7.5"
          />
        </svg>
      </i>
      <i className="switchRight" onClick={() => handleScroll("right")}>
        <svg
          xmlns="http://www.w3.org/2000/svg"
          fill="none"
          viewBox="0 0 24 24"
          strokeWidth={1.5}
          stroke="currentColor"
          className="w-4 h-4 xl:w-6 xl:h-6"
        >
          <path
            strokeLinecap="round"
            strokeLinejoin="round"
            d="M8.25 4.5l7.5 7.5-7.5 7.5"
          />
        </svg>
      </i>
    </div>
  )
}
