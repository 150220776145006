import React, { useState, useEffect, useRef } from "react"
import loadable from "@loadable/component"
import { propertyTypes } from "../mocks/propertyTypes"
import { offertTypes } from "../mocks/offertTypes"

const FilterSearch = loadable(() => import("./FilterSearch"))
export const SearchBar = ({
  handleValuePropertyType,
  handleValueOffertType,
  type,
  offert,
  inputRef,
  handleSubmit,
}) => {
  const [isOpenOffertDesktopDropdown, setIsOpenOffertDesktopDropdown] =
    useState(false)
  const [
    isOpenPropertyTypeDesktopDropdown,
    setisOpenPropertyTypeDesktopDropdown,
  ] = useState(false)
  const dropDownOffertRef = useRef(null)
  const dropDownPropertyTypeRef = useRef(null)
  useEffect(() => {
    const handleClickOutside = (event) => {
      if (
        dropDownOffertRef.current &&
        !dropDownOffertRef.current.contains(event.target) &&
        !event.target.classList.contains("dropdown-close-button-offert")
      ) {
        setIsOpenOffertDesktopDropdown(false)
      }
      if (
        dropDownPropertyTypeRef.current &&
        !dropDownPropertyTypeRef.current.contains(event.target) &&
        !event.target.classList.contains("dropdown-close-button-property")
      ) {
        setisOpenPropertyTypeDesktopDropdown(false)
      }
    }
    document.addEventListener("mousedown", handleClickOutside)

    return () => {
      document.removeEventListener("mousedown", handleClickOutside)
    }
  })
  return (
    <form
      onSubmit={handleSubmit}
      className="flex h-16 mt-6 bg-white rounded-xl shadow-xl sm:h-20 lg:h-24"
    >
      {/*Ipunt city*/}
      <div className="relative w-60 h-full text-base sm:w-44 md:w-44 xl:w-[400px]">
        <input
          type="text"
          className="w-full h-full px-2 bg-transparent rounded-l-xl text-sm outline-none hover:bg-amber-200 hover:bg-opacity-30 transition-all ease-in cursor-pointer"
          id="city"
          placeholder="Ciudad"
          ref={inputRef}
          autoFocus
        />
      </div>
      {/*Filter Property type*/}
      <div className="hidden relative flex-col hover:bg-amber-200 hover:bg-opacity-30 transition-all ease-in sm:flex sm:w-32 md:w-40 lg:w-64">
        <div className="p-2 text-sm font-medium cursor-pointer lg:text-base">
          Inmueble
        </div>
        <button
          aria-expanded={isOpenPropertyTypeDesktopDropdown}
          aria-controls="dropdown-property-type-menu"
          aria-haspopup="true"
          className="flex w-full h-full justify-between items-center px-2 outline-none text-base font-medium dropdown-close-button-property"
          onClick={() =>
            setisOpenPropertyTypeDesktopDropdown((prevState) => !prevState)
          }
          type="button"
        >
          {type ? (
            <span className="text-xs md:text-sm">{type}</span>
          ) : (
            <span className="text-xs md:text-sm">Selecciona</span>
          )}
          <img
            className={`w-6 h-6 dropdown-close-button-property ${
              isOpenPropertyTypeDesktopDropdown ? "rotate-180" : ""
            } `}
            src="/icons/caret.svg"
            alt="caret"
          />
        </button>
        <div
          id="dropdown-property-type-menu"
          className={`absolute top-32 h-[400px] p-6 overflow-y-scroll rounded-2xl bg-white cursor-pointer shadow-lg transition-opacity duration-300
              ${
                isOpenPropertyTypeDesktopDropdown
                  ? "opacity-100 pointer-events-auto"
                  : "opacity-0 pointer-events-none"
              }
            `}
          ref={dropDownPropertyTypeRef}
        >
          <div className="flex flex-wrap gap-x-2 gap-y-3 text-zinc-700 overflow-hidden sm:w-[300px] md:w-[400px]">
            {propertyTypes.map((option) => (
              <FilterSearch
                key={option.id}
                value={option.value}
                handleFilter={handleValuePropertyType}
                typeFilter={type}
                icon={option.icon}
              />
            ))}
          </div>
        </div>
      </div>
      {/*Filter Offert type*/}
      <div className="hidden relative flex-col hover:bg-amber-200 hover:bg-opacity-30 transition-all ease-in sm:flex sm:w-32  md:w-40 xl:w-64">
        <div className="p-2 text-sm font-medium cursor-pointer lg:text-base ">
          Oferta
        </div>
        <button
          aria-expanded={isOpenOffertDesktopDropdown}
          aria-controls="dropdown-menu-offert"
          aria-haspopup="true"
          className="flex w-full h-full justify-between items-center px-2 text-base font-medium outline-none dropdown-close-button-offert"
          onClick={() =>
            setIsOpenOffertDesktopDropdown((prevState) => !prevState)
          }
          type="button"
        >
          {offert ? (
            <span className="text-xs md:text-sm">{offert}</span>
          ) : (
            <span className="text-xs md:text-sm">Selecciona</span>
          )}

          <img
            className={`w-6 h-6 dropdown-close-button-offert ${
              isOpenOffertDesktopDropdown ? "rotate-180" : ""
            }`}
            src="/icons/caret.svg"
            alt="caret"
          />
        </button>
        <div
          id="dropdown-menu-offert"
          className={`absolute top-32 p-6 rounded-2xl bg-white shadow-lg cursor-pointer transition-opacity duration-300
              ${
                isOpenOffertDesktopDropdown
                  ? "opacity-100 pointer-events-auto"
                  : "opacity-0 pointer-events-none"
              }
            `}
          ref={dropDownOffertRef}
        >
          <div className="flex flex-wrap gap-x-2 gap-y-3 text-zinc-700 md:w-[300px]">
            {offertTypes.map((option) => (
              <FilterSearch
                key={option.id}
                value={option.value}
                handleFilter={handleValueOffertType}
                typeFilter={offert}
                icon={option.icon}
              />
            ))}
          </div>
        </div>
      </div>
      <button
        className="px-4 text-center rounded-r-xl font-medium bg-yellowLogo hover:bg-opacity-30 transition-all ease-in sm:w-40 md:h-full md:text-base "
        type="submit"
      >
        Buscar
      </button>
    </form>
  )
}
