import React from "react"

export default function PrivacyPolice() {
  const personalData = [
    {
      id: 1,
      name: "Nombre o razón social:",
    },
    {
      id: 2,
      name: "Tipo de solicitante:",
    },
    {
      id: 3,
      name: "Número de identificación:",
    },
    {
      id: 4,
      name: "Teléfono o celular:",
    },

    {
      id: 5,
      name: "Correo Electrónico:",
    },
    {
      id: 6,
      name: "Medio Para recibir respuesta:",
    },
    {
      id: 7,
      name: "Ciudad:",
    },
    {
      id: 8,
      name: "Departamento:",
    },
    {
      id: 9,
      name: "Dirección de correspondencia:",
    },
    {
      id: 10,
      name: "Edad:",
    },
    {
      id: 11,
      name: "Profesión:",
    },
    {
      id: 12,
      name: "Información y características completas del inmueble, el cual, está negociando",
    },
  ]
  const treatmentAndPurposes = [
    {
      id: 3.1,
      name: "Conocer y controlar la solicitud de bienes o servicios prestados por la empresa, las cuales desea conocer el cliente.",
    },
    {
      id: 3.2,

      name: "Atender y remitir las respuestas de las consultas de los clientes internos y externos; proveedores, trabajadores; auditores internos y externos; entidades públicas, entes de control.",
    },
    {
      id: 3.3,

      name: "Prestar el servicio por parte de nuestros contratistas, empleados, proveedores, etc.",
    },
    {
      id: 3.4,

      name: "Comunicar ofertas publicitarias a nuestros clientes.",
    },
    {
      id: 3.5,

      name: "Actualizar base de datos.",
    },
    {
      id: 3.6,

      name: "Elaborar estudios, estadísticas, encuestas, estudios de mercado, analizar tendencias, relacionados con los bienes y servicios ofrecidos por la INDOLES Ltda.",
    },

    {
      id: 3.7,

      name: "Presentar informes ante las entidades de auditoría interna, externa, entidades de control fiscal, judicial, etc.",
    },
    {
      id: 3.8,

      name: "Gestionar la información necesaria para el cumplimiento de las obligaciones tributarias, contractuales, comerciales, contables, financieros, registros, laborales; fusión, consolidación, adquisición, desinversión, u otro proceso de reestructuración; evaluación; licitaciones, propuestas ante entidades públicas y privadas, presentar nuestro portafolio de bienes y prestar los servicios en los diferentes canales de comunicación de INDOLES LTDA.",
    },
    {
      id: 3.9,
      name: "Transmitir la información de los datos personales en los términos del Decreto 1377 de 2013, a encargados nacionales o internacionales con los que se tenga una relación operativa que provean los bienes o servicios necesarios para la debida operación de la entidad.",
    },
    {
      id: 3.1,
      name: "Presentar o mostrar registro de imágenes o cualquier otro registro que sirva de soporte y evidencia de los bienes y servicios que presta INDOLES LTDA.",
    },
    {
      id: 3.11,

      name: "Las demás actividades que correspondan en la razón social, comercial, empresarial, promocional, jurídica, contable, financiera, laboral, obligaciones, marketing, auditorías y requerimientos que tenga INDOLES LTDA.",
    },
    {
      id: 3.12,
      name: "Con la aceptación de la presente Política de Privacidad, los Titulares de los datos personales autorizan a INDOLES LTDA, para recibir, enviar, comunicar, publicitar, llamar, mostrar, por los diferentes canales de comunicación de nuestra empresa como  página web o internet, email o correo electrónico, mensajes de texto o SMS, llamadas, formatos impresos, visitas personales, etc; información de nuestras ofertas de productos de bienes y servicios, los cuales, ofrecemos tanto a nuestros clientes internos y externos, proveedores, empleados, contratistas, interesados, etc.",
    },
  ]

  return (
    <div className="mx-10 my-12 lg:px-12">
      <h1 className="my-5 font-medium text-3xl text-customBlack sm:text-5xl sm:my-12 ">
        Política de privacidad
      </h1>
      <p className="font-normal mb-5 text-justify text-sm text-gray-700 sm:text-base">
        INDOLES LTDA es una empresa legalmente constituida ante Cámara de
        Comercio, la cual, apoya con soluciones integrales a los clientes con
        proyectos de Ingeniería Civil de infraestructura, vivienda e industria;
        prestando servicios de diseño estructural, consultoría, interventoría,
        construcción, elaboración de presupuestos, programación de obras
        civiles, solicitud de licencias de construcción. Además, ofrecemos para
        rentar y/o vender bienes inmuebles y avalúos comerciales. y a través del
        market place, ofrecemos un portafolio de productos de elementos de
        protección personal para la seguridad y salud en el trabajo y servicio
        de alquiler de equipos para la construcción.
      </p>
      <p className="font-normal mb-5 text-justify text-sm text-gray-700 sm:text-base">
        En INDOLES LTDA, pensamos en la privacidad de la información que usted
        nos confía, por lo tanto, queremos que conozca nuestro procedimiento
        para la recolección, recopilación, el mantenimiento, uso, divulgación y
        eliminación o disposición final de los datos personales, incluyendo la
        autorización de los titulares. Para la presente Política de Privacidad
        y/o Política de tratamiento de la información sobre los datos personales
        se debe cumplir con la legislación legal vigente, regida en la Ley 1581
        de 2012 y en el Decreto 1377 de 2013. Nuestra empresa elabora, se
        compromete y demuestra como maneja la información que INDOLES LTDA
        recopila a través de nuestro sitio web, plataforma o aplicativos; y en
        los documentos físicos o electrónicos elaborados en las actividades
        cotidianas de la empresa.
      </p>
      <p className="font-normal mb-5 text-justify text-sm text-gray-700 sm:text-base">
        La empresa se compromete y comprometerán a sus empleados a través de una
        cláusula de confidencialidad y de manejo de información de manera
        responsable de sus datos. Además, nos comprometemos en el uso
        responsable de la información, incluyendo controles de seguridad
        administrativos, físicos y tecnológicos.
      </p>
      <p className="font-normal mb-5 text-justify text-sm text-gray-700 sm:text-base">
        A través de varios canales se recibirán las Peticiones, quejas,
        denuncias y reclamos:
      </p>
      <p className="font-normal mb-5 text-gray-700 sm:text-base">
        Razón social: Ingeniería y Dotaciones Industriales Ltda{" "}
        <strong>(INDOLES LTDA)</strong>. Dirección Neiva:{" "}
        <strong>
          Carrera 1 A #36-24 Barrio Cándido, Ciudad: Neiva (Huila) Colombia.
        </strong>{" "}
        Dirección Bogotá D.C.: Sede Bogotá D.C. Celular:{" "}
        <strong>3186081940</strong> Email:
        <strong>indolesltda@gmail.com</strong>
      </p>
      <h2 className="mt-10 mb-5 font-medium text-xl text-customBlack sm:text-2xl sm:leading-7">
        1. Aceptación de la presente Política de privacidad de Datos personales:
      </h2>
      <p className="font-normal mb-5 text-justify text-sm text-gray-700 sm:text-base">
        Se debe aceptar la Política de Tratamiento de Datos antes que el Titular
        de la información nos informe sus datos, a través de cualquier canal de
        atención como nuestra página web, Email, correspondencia escrita,
        formatos, telefónicamente, WhatsApp, redes sociales, plataformas, etc.
        Al aceptar la presente Política de Tratamiento de Datos, el Titular de
        la información autoriza a INDOLES LTDA como responsable de la
        información a tratar sus datos conforme y finalidades que en el presente
        documento se indican.
      </p>
      <p className="font-normal mb-5 text-justify text-sm text-gray-700 sm:text-base">
        El Tratamiento de Datos se informa como la recolección, recopilación,
        registro, procesamiento, almacenamiento, el mantenimiento, circulación,
        uso, divulgación, transmisión, supresión, transferencia, eliminación o
        disposición final de los datos personales, dentro y fuera del país,
        incluyendo:
      </p>
      <ul className="text-gray-700">
        <li className="font-normal mb-3 text-justify text-sm text-gray-700 sm:text-base">
          • Requerimiento de información de las entidades de control o
          judiciales.
        </li>
        <li className="font-normal mb-3 text-justify text-sm text-gray-700 sm:text-base">
          • Requerimiento de información para estudios de mercadeo propios de
          INDOLES LTDA.
        </li>
        <li className="font-normal mb-3 text-justify text-sm text-gray-700 sm:text-base">
          • Para cumplir con informar en las auditorías internas de INDOLES
          LTDA.
        </li>
        <li className="font-normal mb-3 text-justify text-sm text-gray-700 sm:text-base">
          • Para enviar al Titular por diferentes canales de comunicación,
          ofertas económicas, y demás, actividades relacionadas con nuestros
          productos y servicios prestados por INDOLES LTDA.
        </li>
      </ul>
      <h2 className="mt-10 mb-5 font-medium text-xl text-customBlack sm:text-2xl sm:leading-7">
        2. Datos personales que se almacenan en nuestras bases de datos.
      </h2>
      <p className="font-normal mb-5 text-justify text-sm text-gray-700 sm:text-base">
        La información recolectada en nuestra base de datos se utiliza y trata
        para cumplir con nuestra razón social de INDOLES LTDA. Esta es la lista
        de datos personales recolectados:
      </p>
      <h3 className="mt-5 mb-5 font-normal text-xl text-customBlack leading-5">
        Información personal
      </h3>
      <ul className="text-gray-700">
        {personalData.map((data) => (
          <li
            className="font-normal mb-3 text-justify text-sm text-gray-700 sm:text-base"
            key={data.id}
          >
            • {data.name}
          </li>
        ))}
      </ul>
      <p className="font-normal mb-5 text-justify text-sm text-gray-700 sm:text-base">
        Igualmente, a los trabajadores y/o clientes internos y externos
        solicitamos información personal con la fecha de nacimiento, fecha de
        expedición de la cédula, estado civil, sexo, grado de escolaridad,
        experiencia laboral, referencias personales, nivel y estado
        socioeconómico, situación financiera. Información de antecedentes
        disciplinarios, fiscales, judiciales, etc. Información de afiliaciones a
        EPS, AFP, ARL, Seguridad social. Información relacionada con su familia
        y dependientes; referencias comerciales, financieras, laborales,
        académicas. Se conoce además los hobbies y contratos laborales externos.
        Información fotográfica de su hoja de vida, videollamadas y registros de
        cámaras de seguridad en el desarrollo de labores propias con INDOLES
        Ltda.{" "}
      </p>
      <p className="font-normal mb-5 text-justify text-sm text-gray-700 sm:text-base">
        El Tratamiento de Datos de la información suministrada será almacenada
        por el término máximo necesario para el desarrollo de las actividades
        propias del objeto comercial de INDOLES Ltda; contractual, tributario,
        contabilidad, estudio de mercado, administrativos, comerciales,
        jurídicos, históricos, laborales, etc. Creemos en la buena fe de las
        personas, quienes, nos están entregando información real, autentica,
        suficiente, de sus datos; por lo tanto, no asumimos la obligación de
        verificar la veracidad de la información.
      </p>
      <h2 className="mt-10 mb-5 font-medium text-xl text-customBlack sm:text-2xl sm:leading-7">
        3. Tratamiento y finalidades
      </h2>
      <p className="font-normal mb-5 text-justify text-sm text-gray-700 sm:text-base">
        De acuerdo con lo establecido en la Ley 1581 de 2012 y de conformidad
        con las autorizaciones impartidas por los Titulares de la información;
        el Titular autoriza a INGENIERÍA Y DOTACIONES INDUSTRIALES LTDA (INDOLES
        LTDA), a la recolección, recopilación, registro, procesamiento,
        almacenamiento, el mantenimiento, circulación, uso, divulgación,
        transmisión, supresión, transferencia, eliminación o disposición final
        de los datos personales, dentro y fuera del país; para finalidades
        autorizadas y previstas en la presente Política y en las autorizaciones
        específicas otorgadas por parte del Titular.
      </p>
      <p className="font-normal mb-5 text-justify text-sm text-gray-700 sm:text-base">
        Por la naturaleza jurídica de INGENIERÍA Y DOTACIONES INDUSTRIALES LTDA
        (INDOLES LTDA), en todos los casos los datos personales podrán ser
        tratados de acuerdo con la finalidad señaladas con la presente Política
        de Privacidad, de acuerdo a nuestro objeto social y el grupo de interés
        y en proporción a la autorización, así:
      </p>
      <ul className="font-normal mb-5 text-justify text-sm text-gray-700 sm:text-base">
        {treatmentAndPurposes.map((purpose, index) => (
          <li className="mb-3" key={index}>
            <strong className="mr-1">{purpose.id}</strong>

            {purpose.name}
          </li>
        ))}
      </ul>
      <h2 className="mt-10 mb-5 font-medium text-xl text-customBlack sm:text-2xl sm:leading-7">
        4. Seguridad
      </h2>
      <p className="font-normal mb-5 text-justify text-sm text-gray-700 sm:text-base">
        En nuestra política está el uso responsable de la información,
        incluyendo controles de seguridad administrativos, físicos y
        tecnológicos. Esto con el objetivo de mantener la confidencialidad de la
        información. Con los clientes internos (empleados, proveedores,
        contratistas, auditores, outsourcing de mercadeo, creadores de página
        web, colaboradores, temporales, etc) y clientes externos, los
        comprometeremos para la confidencialidad de los datos personales,
        privacidad y protección de la información.
      </p>
      <p className="font-normal mb-5 text-justify text-sm text-gray-700 sm:text-base">
        Creemos en la buena fe de los Titulares de la información brindada,
        quienes son personas con el deber de entregar la información real,
        autentica, suficiente, cierta, de sus datos; con el objetivo de poder
        satisfacer con los bienes y servicios, los cuales, presta INDOLES LTDA a
        sus clientes.
      </p>
      <h2 className="mt-10 mb-5 font-medium text-xl text-customBlack sm:text-2xl sm:leading-7">
        5. Derechos y procedimiento
      </h2>
      <p className="font-normal mb-5 text-justify text-sm text-gray-700 sm:text-base">
        El Titular en este caso usted manifiesta libre, expresa que previamente
        haber sido informado sobre los derechos que la Ley le concede como
        titular de sus datos personales, así:
      </p>
      <ul className="text-gray-700">
        <li className="mb-3">
          • Conocer, actualizar y rectificar los Datos frente a él o los
          Encargados del tratamiento de datos.
        </li>
        <li className="mb-3">
          • Solicitar prueba de la autorización otorgada, o cualquier otra que
          suscriba el Titular de los Datos Personales para el efecto, salvo
          cuando expresamente se exceptúe como requisito para el Tratamiento de
          Datos de conformidad con la Ley.
        </li>
        <li className="mb-3">
          • Ser informado por el responsable del Tratamiento o el Encargado del
          Tratamiento, previa solicitud, respecto del uso que se le ha dado a
          los datos.
        </li>
        <li className="mb-3">
          • Presentar ante la Superintendencia de Industria y Comercio quejas
          por infracciones a lo dispuesto en la Ley y las demás normas que la
          modifiquen, sustituyan o adicionen con relación a infracciones al
          régimen de protección de datos personales.
        </li>
        <li className="mb-3">
          • Revocar la autorización y/o solicitar la supresión del dato personal
          cuando en el tratamiento no se respeten los principios, derechos y
          garantías constitucionales y legales. La revocatoria y/o supresión
          procederá cuando la Superintendencia de Industria y Comercio, haya
          determinado que, en el Tratamiento INDOLES LTDA, o Encargados del
          Tratamiento de Datos Personales, han incurrido en conductas contrarias
          a la Ley 1581 de 2012 y a la Constitución. La revocatoria procederá
          siempre y cuando no exista la obligación legal o contractual de
          conservar el dato personal.
        </li>
        <li className="mb-3">
          • Acceder en forma gratuita una vez por mes a sus Datos Personales que
          hayan sido objeto de tratamiento, en los términos de la normatividad
          vigente.
        </li>
      </ul>
      <h2 className="mt-10 mb-5 font-medium text-xl text-customBlack sm:text-2xl sm:leading-7">
        6. Procedimiento para las consultas
      </h2>
      <p className="font-normal mb-5 text-justify text-sm text-gray-700 sm:text-base">
        INDOLES LTDA y/o Encargados, garantizan a los Titulares de datos
        personales contenidos en nuestra base de datos, o causahabientes o
        personas autorizadas, el derecho de consultar toda la información
        contenida en su registro individual o toda aquella que esté vinculada
        con su identificación conforme se establece en la presente Política de
        Tratamiento de Datos Personales. La consulta será atendida en un termina
        máximo de diez (10) días hábiles contados a partir de la fecha de recibo
        de la misma. Cuando no fuere posible atender la consulta dentro de dicho
        término, se le informará, los motivos, por los cuales, se demora la
        entrega de dicha información consultada, el cual, en ningún caso podrá
        superar los cinco (5) días hábiles siguientes, contados, a partir del
        vencimiento del primer término.
      </p>
      <h2 className="mt-10 mb-5 font-medium text-xl text-customBlack sm:text-2xl sm:leading-7">
        7. Procedimiento de reclamos
      </h2>
      <p className="font-normal mb-5 text-justify text-sm text-gray-700 sm:text-base">
        • Corrección o Actualización: INDOLES LTDA y/o los Encargados,
        garantizarán a los titulares de datos personales contenidos en sus bases
        de datos o a sus causahabientes, el derecho de corregir o actualizar los
        datos personales que reposen en nuestra base de datos, mediante
        presentación de reclamación, cuando consideren que se cumplen los
        parámetros establecidos por la Ley o los señalados en la presente
        Política de Tratamiento de Datos Personales para que sea procedente la
        solicitud de Corrección o Actualización.
      </p>
      <p className="font-normal mb-5 text-justify text-sm text-gray-700 sm:text-base">
        Si los Titulares o las personas autorizadas consideran que, en nuestra
        base de datos, se debe hacer la corrección, actualización, o supresión o
        adviertan el presunto incumplimiento de cualquier de los deberes
        contenidos en la Ley, podrán presentar un reclamo ante nosotros, el
        cual, será tramitado bajo las siguientes especificaciones:
      </p>
      <p className="font-normal mb-5 text-justify text-sm text-gray-700 sm:text-base">
        • Puede enviar su solicitud a nuestros canales de comunicación(email
        indolesltda@gmail.com), dirigida a INDOLES LTDA con sus datos de
        identificación (Nombre y apellidos del titular y/o persona autorizada
        y/o representante y/o causahabientes), lo que se pretende consultar, con
        la descripción de los hechos que dan lugar al reclamo, su dirección
        física email o correo electrónico de notificación, con los documentos
        soportes, firma de documento, número de identificación o procedimiento
        de validación correspondiente. Si está incompleta la solicitud, le
        solicitaremos que dentro de los cinco (5) días contados a partir de la
        recepción del mismo para que subsane el error. Si transcurrido dos (2)
        meses desde la fecha de requerimiento, sin que usted presente la
        información requerida, entenderemos que ha desistido del reclamo, en los
        términos del artículo 17 de la Ley 1755 de 2015, estatutaria del Derecho
        de Petición.
      </p>
      <p className="font-normal mb-5 text-justify text-sm text-gray-700 sm:text-base">
        • En caso de no resolver su reclamo, INDOLES LTDA dará traslado a quien
        corresponda en un término de dos (2) días hábiles hecho que le será
        informado al Titular de manera oportuna.
      </p>
      <p className="font-normal mb-5 text-justify text-sm text-gray-700 sm:text-base">
        • De ser procedente, una vez recibido el reclamo completo, se incluirá
        en la base de datos una leyenda de “reclamo en trámite” y el motivo del
        mismo, en un término no mayor a dos (2) día hábiles; esto mientras se
        subsana.
      </p>
      <p className="font-normal mb-5 text-justify text-sm text-gray-700 sm:text-base">
        • El término máximo para atender el reclamo será de quince (15) días
        hábiles contados a partir del día siguiente a la fecha de su recibo.
        Sino fuere posible atender el reclamo dentro de dicho término, se le
        informará los motivos de dicha demora, en un término de ocho (8) días
        hábiles siguientes al vencimiento del primer término.
      </p>
      <h2 className="mt-10 mb-5 font-medium text-xl text-customBlack sm:text-2xl sm:leading-7">
        8. Responsable de atención de Reclamos:
      </h2>
      <p className="font-normal mb-5 text-justify text-sm text-gray-700 sm:text-base">
        Los Titulares de datos personales pueden ejercer sus derechos de
        conocer, actualizar, rectificar y suprimir sus datos personales enviando
        su solicitud al correo electrónico{" "}
        <strong>indolesltda@gmail.com</strong>, o al teléfono móvil 3186081940,
        de conformidad con lo establecido en esta Política de Privacidad.
      </p>
      <p className="font-normal mb-5 text-justify text-sm text-gray-700 sm:text-base">
        La Política de Privacidad está sujeto a cambios en sus términos y
        condiciones. Los cambios se informarán a través de la página web de
        INDOLES LTDA. Las bases de datos tendrán vigencia indefinida, según el
        propósito y uso de la información.
      </p>
      <p className="font-normal mb-5 text-justify text-sm text-gray-700 sm:text-base">
        Esta política será publicada en nuestra página web septiembre de 2023 y
        entra vigencia a partir de la fecha de publicación.
      </p>
      <ul className="font-normal mb-5 text-justify text-sm text-gray-700 sm:text-base">
        <li className="mb-3">
          Razón social: Ingeniería y Dotaciones Industriales Ltda (INDOLES
          LTDA).
        </li>
        <li className="mb-3">
          Dirección Neiva: Carrera 1 A #36-24 Barrio Cándido, Ciudad: Neiva
          (Huila) Colombia.
        </li>
        <li className="mb-3">Dirección Bogotá D.C.: Sede Bogotá D.C.</li>
        <li className="mb-3">Celular: 3186081940</li>
        <li className="mb-3">Email: indolesltda@gmail.com</li>
      </ul>
    </div>
  )
}
