import React from "react"
import { axiosApi } from "../../utils/axiosInstance"
import { useForm } from "react-hook-form"
import { toast } from "react-toastify"
import ButtonSendForm from "../Buttons/ButtonSendForm"
import CheckBox from "../Fields/CheckBox"
import FormInput from "../Fields/FormInput"
export default function WorkWithUs() {
  const {
    formState: { errors },
    handleSubmit,
    register,
    reset,
    setValue,
    watch,
  } = useForm()

  const values = watch()

  const submitNewApplication = async (data) => {
    try {
      const formData = new FormData()
      formData.append("resume", data.resume[0])
      formData.append("applicationType", data.applicationType)
      formData.append("nit", data.nit)
      formData.append("enterprise", data.enterprise)
      formData.append("document", data.document)
      formData.append("state", data.state)
      formData.append("country", data.country)
      formData.append("name", data.name)
      formData.append("email", data.email)
      formData.append("city", data.city)
      formData.append("numberPhone", data.numberPhone)
      formData.append("typeOfIdentification", data.typeOfIdentification)
      const response = await axiosApi.post("/forms/job", formData)
      if (response.status === 200) {
        toast.success("Tu aplicación ha sido enviada con exito")
        reset()
      } else {
        toast.error("Error enviando la solicitud")
      }
    } catch (error) {
      const erroMessage =
        error.message === "Network Error"
          ? "Error de red"
          : error.response.data || "Error enviando la solicitud"
      console.log(error)
      toast.error(erroMessage)
    }
  }
  return (
    <form
      onSubmit={handleSubmit(submitNewApplication)}
      className="flex flex-col w-80 items-center gap-5 sm:w-[600px] sm:items-start sm:gap-10"
    >
      {/*Type of applicant*/}
      <div className="flex flex-col w-full gap-3">
        <label className="font-medium text-base" htmlFor="applicationType">
          Tipo de solicitante *
        </label>
        <select
          className={`h-[55px] border border-grayTextConsultory rounded cursor-pointer`}
          id="applicationType"
          defaultValue=""
          onChange={(e) => setValue("applicationType", e.target.value)}
          {...register("applicationType", { required: true })}
        >
          <option value="" disabled hidden>
            Seleccione
          </option>
          <option value="Persona jurídica">Persona jurídica</option>
          <option value="Persona natural">Persona natural</option>
        </select>
        {errors.applicationType?.type === "required" && (
          <span className="text-red-700">Este campo es requerido</span>
        )}
      </div>
      {/* Type of identification */}
      <div
        className={`w-full space-y-3 ${
          values.applicationType === "Persona jurídica" ? "sm:w-full" : "sm:flex sm:justify-between"
        }  sm:space-y-0`}
      >
        {values.applicationType !== "Persona jurídica" && (
          <div className="flex flex-col gap-3">
            <label className="font-medium text-base" htmlFor="identification">
              Tipo de identificación *
            </label>
            <select
              className="h-[55px] border border-grayTextConsultory rounded sm:w-[280px]"
              id="identification"
              defaultValue=""
              onChange={(e) => setValue("typeOfIdentification", e.target.value)}
              {...register("typeOfIdentification", { required: true })}
            >
              <option value="" disabled hidden>
                Seleccione
              </option>
              <option value="Cedula de ciudadania">Cédula de ciudadanía</option>
              <option value="Cédula de ciduadanía">Cédula de extranjería</option>
            </select>
            {errors.typeOfIdentification?.type === "required" && (
              <span className="text-red-700">Este campo es requerido</span>
            )}
          </div>
        )}
        {/* ID and Nit*/}
        {values.applicationType === "Persona jurídica" ? (
          <div className="flex flex-col gap-3">
            <FormInput
              label="nit"
              name="Nit *"
              placeholder="900.090.125-1"
              width="w-full"
              rules={{
                required: true,
                minLength: 9,
                maxLength: 9,
              }}
              type="number"
              register={register}
              errors={errors}
            />
            {errors.nit?.type === "minLength" && (
              <span className="text-red-700">El nit debe contener 9 digitos</span>
            )}
            {errors.nit?.type === "maxLength" && (
              <span className="text-red-700">El nit debe contener máximo 9 digitos</span>
            )}
          </div>
        ) : (
          <div className="flex flex-col gap-3">
            <FormInput
              label="document"
              name="Número de identificación *"
              placeholder="7700809"
              rules={{
                maxLength: 10,
              }}
              type="number"
              register={register}
              width="sm:w-[280px]"
              errors={errors}
            />

            {errors.document?.type === "maxLength" && (
              <span className="text-red-700">El máximo de digitos son 10</span>
            )}
          </div>
        )}
      </div>
      {/* Enterprise */}
      {values.applicationType === "Persona jurídica" && (
        <div className="w-full space-y-3 ">
          <>
            <FormInput
              label="enterprise"
              name="Empresa *"
              placeholder="Indoles"
              rules={{
                required: true,
                minLength: 3,
                maxLength: 50,
              }}
              type="text"
              register={register}
              errors={errors}
            />

            {errors.enterprise?.type === "minLength" && (
              <span className="text-red-700">Debe tener minimo 3 caracteres</span>
            )}
          </>
        </div>
      )}
      {/* Name and Email */}
      <div className="w-full space-y-3 sm:flex sm:w-full sm:justify-between sm:space-y-0">
        <div className="flex flex-col gap-3">
          <FormInput
            label="name"
            width="sm:w-[280px]"
            errors={errors}
            name={"Nombres y apellidos *"}
            placeholder="Alexader"
            register={register}
            rules={{
              required: true,
              pattern: /^[A-Za-z\s]+$/,
            }}
            type="text"
          />
          {errors.name?.type === "pattern" && (
            <span className="text-red-700">El nombre solo puede contener letras</span>
          )}
        </div>
        <div className="flex flex-col gap-3">
          <FormInput
            label="email"
            width="sm:w-[280px]"
            errors={errors}
            name={"Correo electrónico *"}
            placeholder="administrador@indolesltda.com"
            register={register}
            rules={{
              required: true,
              pattern: /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/i,
            }}
            type="email"
          />
          {errors.email?.type === "pattern" && (
            <span className="text-red-700">El correo no sigue el patron correcto</span>
          )}
        </div>
      </div>
      {/* City and Number phone */}
      <div className="w-full space-y-3 sm:flex sm:w-full sm:justify-between sm:space-y-0">
        <div className="flex flex-col gap-3">
          <FormInput
            label="city"
            width="sm:w-[280px]"
            errors={errors}
            name={"Ciudad *"}
            placeholder="Neiva"
            register={register}
            rules={{
              required: true,
            }}
            type="text"
          />
        </div>
        <div className="flex flex-col gap-3">
          <FormInput
            label="numberPhone"
            width="sm:w-[280px]"
            errors={errors}
            name={"Teléfono *"}
            placeholder="3213056326"
            register={register}
            rules={{
              required: true,
              minLength: 10,
              maxLength: 10,
            }}
            type="number"
          />
          {errors.numberPhone?.type === "minLength" && (
            <span className="text-red-700">El número debe contener 10 digitos</span>
          )}
          {errors.numberPhone?.type === "maxLength" && (
            <span className="text-red-700">El número debe contener 10 digitos</span>
          )}
        </div>
      </div>
      {/* State and Country */}
      <div className="w-full space-y-3 sm:flex sm:w-full sm:justify-between sm:space-y-0">
        <div className="flex flex-col gap-3">
          <FormInput
            label="state"
            name="Departamento *"
            placeholder="Huila"
            rules={{
              required: true,
            }}
            type="text"
            register={register}
            width="sm:w-[280px]"
            errors={errors}
          />
        </div>
        <div className="flex flex-col gap-3">
          <FormInput
            label="country"
            name="País *"
            placeholder="Colombia"
            rules={{
              required: true,
            }}
            type="text"
            register={register}
            errors={errors}
            width="sm:w-[280px]"
          />
          {errors.numberPhone?.type === "minLength" ||
            (errors.numberPhone?.type === "maxLength" && (
              <span className="text-red-700">El número debe contener 10 digitos</span>
            ))}
        </div>
      </div>
      {/* Resume */}
      <div className="flex flex-col gap-3">
        <label className="font-medium text-base" htmlFor="file">
          Adjuntar hoja de vida o RUT* (pdf max 1mb)
        </label>
        <input
          id="file"
          type="file"
          accept="application/pdf"
          {...register("resume", { required: true })}
        />
        {errors.resume?.type === "required" && (
          <span className="text-red-700">Este campo es requerido</span>
        )}
      </div>
      <CheckBox
        label="termsOfUse"
        errors={errors}
        register={register}
        rules={{
          required: true,
        }}
      />
      <ButtonSendForm />
    </form>
  )
}
