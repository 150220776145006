import React from "react"

export const CardDataProperty = ({ label, url, value }) => {
  return (
    <div className="flex flex-col px-2 items-center border-r-2 border-yellowLogo sm:flex-row ">
      <img src={url} width={24} height={24} alt="Char area icon" />
      <h3 className="flex flex-col items-center h-[49px] ml-2 text-xs sm:text-sm sm:items-start">
        <span className="text-center font-semibold sm:text-left">{value.toLocaleString()}</span>
        <span>{label.toLocaleString()}</span>
      </h3>
    </div>
  )
}
