// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.animate-whatsapp-logo {
  animation: whatsapp-logo 2s ease forwards;
}
@keyframes whatsapp-logo {
  0% {
    opacity: 0;
    transform: translateX(20px);
  }
  100% {
    opacity: 1;
    transform: translateX(0);
  }
}

@media (max-width: 768px) {
  .animate-whatsapp-logo {
    animation: whatsapp-logo 2s ease forwards;
  }
  @keyframes whatsapp-logo {
    0% {
      opacity: 0;
      transform: translateX(10px);
    }
    100% {
      opacity: 1;
      transform: translateX(0);
    }
  }
}
`, "",{"version":3,"sources":["webpack://./src/styles/components/ButtonWhatsapp.css"],"names":[],"mappings":"AAAA;EACE,yCAAyC;AAC3C;AACA;EACE;IACE,UAAU;IACV,2BAA2B;EAC7B;EACA;IACE,UAAU;IACV,wBAAwB;EAC1B;AACF;;AAEA;EACE;IACE,yCAAyC;EAC3C;EACA;IACE;MACE,UAAU;MACV,2BAA2B;IAC7B;IACA;MACE,UAAU;MACV,wBAAwB;IAC1B;EACF;AACF","sourcesContent":[".animate-whatsapp-logo {\n  animation: whatsapp-logo 2s ease forwards;\n}\n@keyframes whatsapp-logo {\n  0% {\n    opacity: 0;\n    transform: translateX(20px);\n  }\n  100% {\n    opacity: 1;\n    transform: translateX(0);\n  }\n}\n\n@media (max-width: 768px) {\n  .animate-whatsapp-logo {\n    animation: whatsapp-logo 2s ease forwards;\n  }\n  @keyframes whatsapp-logo {\n    0% {\n      opacity: 0;\n      transform: translateX(10px);\n    }\n    100% {\n      opacity: 1;\n      transform: translateX(0);\n    }\n  }\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
