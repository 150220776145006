import React from "react"
import { axiosApi } from "../../utils/axiosInstance"
import { useForm } from "react-hook-form"
import { toast } from "react-toastify"
import { ButtonSendForm } from "../Buttons/ButtonSendForm"
import CheckBox from "../Fields/CheckBox"
import FormInput from "../Fields/FormInput"
export const Pqrs = () => {
  const {
    register,
    handleSubmit,
    formState: { errors },
    setValue,
    watch,
    reset,
  } = useForm()
  const values = watch()
  const submitRequestPQRS = async (data) => {
    try {
      const response = await axiosApi.post("/forms/pqrs", data)
      if (response.status === 200) {
        toast.success("PQRS enviada correctamente")
        reset()
      }
    } catch (error) {
      const errorMessage =
        error.message === "Network Error"
          ? "Please check your internet connection"
          : error.response?.data || "Something went wrong"
      toast.error(errorMessage)
    }
  }
  return (
    <form
      onSubmit={handleSubmit(submitRequestPQRS)}
      className="flex flex-col w-80 items-center gap-5 sm:w-[600px] sm:items-start sm:gap-10"
    >
      {/* Type of request */}
      <div className="flex flex-col w-full gap-3 ">
        <label className="font-medium text-base" htmlFor="typeOfRequest">
          Tipo de solicitud *
        </label>
        <select
          className="h-[55px] px-2 border border-grayTextConsultory rounded cursor-pointer"
          id="typeOfRequest"
          defaultValue=""
          onChange={(e) => setValue("typeOfRequest", e.target.value)}
          {...register("typeOfRequest", { required: true })}
        >
          <option value="" disabled hidden>
            Seleccione
          </option>
          <option value="Denuncia">Denuncia</option>
          <option value="Petición de Interés general">Petición de interes general</option>
          <option value="Queja">Queja</option>
          <option value="Reclamo">Reclamo</option>
          <option value="Sugerencia">Sugerencia</option>
        </select>
        {errors.typeOfRequest?.type === "required" && (
          <span className="text-red-700">Este campo es requerido</span>
        )}
      </div>
      {/* File anonymously */}
      <div className="flex flex-col w-full gap-3">
        <label className="font-medium text-base" htmlFor="typeOfRadication">
          ¿Desea radicar anónimamente? *
        </label>
        <select
          className="h-[55px] px-2 border border-grayTextConsultory rounded cursor-pointer"
          id="typeOfRadication"
          defaultValue=""
          onChange={(e) => setValue("typeOfRadication", e.target.value)}
          {...register("typeOfRadication", { required: true })}
        >
          <option value="" disabled hidden>
            Seleccione
          </option>
          <option value="Si">Si</option>
          <option value="No">No</option>
        </select>
        {errors.typeOfRadication?.type === "required" && (
          <span className="text-red-700">Este campo es requerido</span>
        )}
      </div>
      {/*If the user wants to radicate anonymously hide the personal information*/}
      {values.typeOfRadication === "No" ? (
        <div className="flex flex-col w-full gap-5">
          {/* Type of applicant and Sex*/}
          <div
            className={`w-full space-y-3 ${
              values.applicationType === "Persona jurídica"
                ? "sm:w-full"
                : "sm:flex sm:justify-between"
            }  sm:space-y-0`}
          >
            <div className="flex flex-col gap-3">
              <label className="font-medium text-base" htmlFor="applicationType">
                Tipo de solicitante *
              </label>
              <select
                className={`h-[55px] px-2 border border-grayTextConsultory rounded cursor-pointer ${
                  values.applicationType === "Persona jurídica" ? "w-full" : "sm:w-[280px]"
                }`}
                id="applicationType"
                defaultValue=""
                onChange={(e) => setValue("applicationType", e.target.value)}
                {...register("applicationType", { required: true })}
              >
                <option value="" disabled hidden>
                  Seleccione
                </option>
                <option value="Persona jurídica">Persona jurídica</option>
                <option value="Persona natural">Persona natural</option>
              </select>
              {errors.applicationType?.type === "required" && (
                <span className="text-red-700">Este campo es requerido</span>
              )}
            </div>

            {values.applicationType !== "Persona jurídica" && (
              <div className="flex flex-col gap-3">
                <label className="font-medium text-base" htmlFor="sex">
                  Sexo *
                </label>
                <select
                  className="h-[55px] px-2 border border-grayTextConsultory rounded sm:w-[280px]"
                  id="sex"
                  defaultValue=""
                  onChange={(e) => setValue("typeOfSex", e.target.value)}
                  {...register("typeOfSex", { required: true })}
                >
                  <option value="" disabled hidden>
                    Seleccione
                  </option>
                  <option value="Masculino">Masculino</option>
                  <option value="Femenino">Femenino</option>
                  <option value="Otro">Otro</option>
                </select>
                {errors.typeOfSex?.type === "required" && (
                  <span className="text-red-700">Este campo es requerido</span>
                )}
              </div>
            )}
          </div>
          {/* ID type */}
          <div
            className={`w-full space-y-3 ${
              values.applicationType === "Persona jurídica"
                ? "sm:w-full"
                : "sm:flex sm:justify-between"
            }  sm:space-y-0`}
          >
            {values.applicationType !== "Persona jurídica" && (
              <div className="flex flex-col gap-3">
                <label className="font-medium text-base" htmlFor="identification">
                  Tipo de identificación *
                </label>
                <select
                  className="h-[55px] border border-grayTextConsultory rounded sm:w-[280px]"
                  id="identification"
                  defaultValue=""
                  onChange={(e) => setValue("typeOfIdentification", e.target.value)}
                  {...register("typeOfIdentification", { required: true })}
                >
                  <option value="" disabled hidden>
                    Seleccione
                  </option>
                  <option value="Cedula de ciudadania">Cédula de ciudadanía</option>
                  <option value="Cédula de ciduadanía">Cédula de extranjería</option>
                </select>
                {errors.typeOfIdentification?.type === "required" && (
                  <span className="text-red-700">Este campo es requerido</span>
                )}
              </div>
            )}
            {/* ID and Nit*/}
            {values.applicationType === "Persona jurídica" ? (
              <div className="flex flex-col gap-3">
                <FormInput
                  label="nit"
                  name="Nit *"
                  placeholder="900.090.125-1"
                  width="w-full"
                  rules={{
                    required: true,
                    minLength: 9,
                    maxLength: 9,
                  }}
                  type="number"
                  register={register}
                  errors={errors}
                />
                {errors.nit?.type === "minLength" && (
                  <span className="text-red-700">El nit debe contener 9 digitos</span>
                )}
                {errors.nit?.type === "maxLength" && (
                  <span className="text-red-700">El nit debe contener máximo 9 digitos</span>
                )}
              </div>
            ) : (
              <div className="flex flex-col gap-3">
                <FormInput
                  label="document"
                  name="Número de identificación *"
                  placeholder="7700809"
                  rules={{
                    required: true,
                    maxLength: 10,
                  }}
                  type="number"
                  register={register}
                  width="sm:w-[280px]"
                  errors={errors}
                />

                {errors.document?.type === "maxLength" && (
                  <span className="text-red-700">El máximo de digitos son 10</span>
                )}
              </div>
            )}
          </div>
          {/* Enterprise */}
          {values.applicationType === "Persona jurídica" && (
            <div className="w-full space-y-3 ">
              <>
                <FormInput
                  label="enterprise"
                  name="Empresa *"
                  placeholder="Indoles"
                  rules={{
                    required: true,
                    minLength: 3,
                    maxLength: 50,
                  }}
                  type="text"
                  register={register}
                  errors={errors}
                />

                {errors.enterprise?.type === "minLength" && (
                  <span className="text-red-700">Debe tener minimo 3 caracteres</span>
                )}
                {errors.nit?.type === "maxLength" && (
                  <span className="text-red-700">Puede tener máximo 50 caracteres</span>
                )}
              </>
            </div>
          )}
          {/* Name and email */}
          <div className="w-full space-y-3 sm:flex sm:w-full sm:justify-between sm:space-y-0">
            <div className="flex flex-col gap-3">
              <FormInput
                label="name"
                name="Nombres y apellidos *"
                placeholder="Indole LTDA"
                rules={{
                  required: true,
                  minLength: 3,
                  pattern: /^[A-Za-z\s]+$/,
                }}
                type="text"
                register={register}
                errors={errors}
                width="sm:w-[280px]"
              />

              {errors.name?.type === "minLength" && (
                <span className="text-red-700">El nombre debe contener mas de 3 caracteres</span>
              )}
              {errors.name?.type === "pattern" && (
                <span className="text-red-700">El nombre solo puede contener letras</span>
              )}
            </div>
            <div className="flex flex-col gap-3">
              <FormInput
                label="email"
                name="Correo electrónico *"
                placeholder="indoles@gmail.com"
                rules={{
                  required: true,
                  pattern: /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/i,
                }}
                type="email"
                register={register}
                errors={errors}
                width="sm:w-[280px]"
              />
              {errors.email?.type === "pattern" && (
                <span className="text-red-700">El correo no sigue el patron correcto</span>
              )}
            </div>
          </div>
          {/* City and address */}
          <div className="w-full space-y-3 sm:flex sm:w-full sm:justify-between sm:space-y-0">
            <>
              <FormInput
                label="city"
                name="Ciudad *"
                placeholder="Neiva"
                rules={{
                  required: true,
                }}
                type="text"
                register={register}
                width="sm:w-[280px]"
                errors={errors}
              />
            </>
            <>
              <FormInput
                label="address"
                name="Dirección de correspondencia *"
                placeholder="Carrera 1A #36-24 "
                rules={{
                  required: true,
                }}
                type="text"
                register={register}
                errors={errors}
                width="sm:w-[280px]"
              />
            </>
          </div>
          {/* State and country*/}
          <div className="w-full space-y-3 sm:flex sm:w-full sm:justify-between sm:space-y-0">
            <>
              <FormInput
                label="state"
                name="Departamento *"
                placeholder="Huila"
                rules={{
                  required: true,
                }}
                type="text"
                register={register}
                width="sm:w-[280px]"
                errors={errors}
              />
            </>
            <>
              <FormInput
                label="country"
                name="País *"
                placeholder="Colombia"
                rules={{
                  required: true,
                }}
                type="text"
                register={register}
                errors={errors}
                width="sm:w-[280px]"
              />
            </>
          </div>
          {/* Media and phone number */}
          <div className="w-full space-y-3 sm:flex sm:w-full sm:justify-between sm:space-y-0">
            <div className="flex flex-col gap-3">
              <label className="font-medium text-base" htmlFor="media">
                Medio para recibir respuesta *
              </label>
              <select
                className="h-[55px] border border-grayTextConsultory rounded sm:w-[280px] "
                id="media"
                defaultValue=""
                onChange={(e) => setValue(e.target.value)}
                {...register("media", { required: true })}
              >
                <option value="" disabled hidden>
                  Seleccione
                </option>
                <option value="Dirección de correspondencia">Dirección de correspondencia</option>
                <option value="Correo electrónico">Correo electrónico</option>
              </select>
              {errors.media?.type === "required" && (
                <span className="text-red-700">Este campo es requerido</span>
              )}
            </div>
            <div className="flex flex-col gap-3">
              <FormInput
                label="numberPhone"
                name="Teléfono *"
                placeholder="3213056326"
                rules={{
                  required: true,
                  minLength: 10,
                  maxLength: 10,
                }}
                type="number"
                register={register}
                errors={errors}
                width="sm:w-[280px]"
              />
              {errors.numberPhone?.type === "minLength" && (
                <span className="text-red-700">El número debe contener 10 digitos</span>
              )}
              {errors.numberPhone?.type === "maxLength" && (
                <span className="text-red-700">El número debe contener 10 digitos</span>
              )}
            </div>
          </div>
        </div>
      ) : null}
      {/* Affair and description */}
      <div className="flex flex-col w-full gap-3">
        <label className="font-medium text-base" htmlFor="affair">
          Asunto *
        </label>
        <input
          className="h-[55px] px-2 border border-grayTextConsultory rounded "
          id="affair"
          placeholder="Indique el asunto"
          {...register("affair", { required: true })}
        />
        {errors.affair?.type === "required" && (
          <span className="text-red-700">Este campo es requerido</span>
        )}
      </div>
      <div className="flex flex-col w-full gap-3">
        <label className="font-medium text-base" htmlFor="description">
          Descripción *
        </label>
        <textarea
          className="h-[200px] px-2 border border-grayTextConsultory rounded "
          id="description"
          placeholder="Describa la PQRSD"
          {...register("description", { required: true })}
        ></textarea>
        {errors.description?.type === "required" && (
          <span className="text-red-700">Este campo es requerido</span>
        )}
      </div>
      <CheckBox label="termsOfUse" register={register} errors={errors} rules={{ required: true }} />
      <ButtonSendForm />
    </form>
  )
}
