import React, { useState } from "react"
import { offertTypes } from "../../mocks/offertTypes"
import FilterSearch from "../FilterSearch"
export default function DropdownOffertFilter({
  handleValueOffertType,
  offert,
}) {
  const [isOpenOffertMobileDropdown, setIsOpenOffertMobileDropdown] =
    useState(false)
  return (
    <div>
      <div className="flex flex-col rounded border border-yellowLogo hover:bg-amber-200 hover:bg-opacity-30 transition-all ease-in ">
        <div className="px-2 py-2 text-sm font-medium cursor-pointer">
          Oferta
        </div>
        <button
          type="button"
          className="flex justify-between items-center px-2 text-sm "
          onClick={() =>
            setIsOpenOffertMobileDropdown(!isOpenOffertMobileDropdown)
          }
        >
          {offert ? <span>{offert}</span> : <span>Selecciona</span>}

          <img
            className={` w-6 h-6 ${
              isOpenOffertMobileDropdown ? "rotate-180" : ""
            }`}
            src="/icons/caret.svg"
            alt="Caret icon"
          />
        </button>
        {isOpenOffertMobileDropdown && (
          <div className="fixed left-0 top-0 w-full h-full bg-black bg-opacity-90 z-20">
            <div className="fixed left-1/2 top-1/4 transform -translate-x-1/2 w-[300px] rounded bg-white cursor-pointer z-50">
              <button
                type="button"
                className="absolute left-2 top-2 py-1 px-2 rounded hover:bg-gray-300"
                onClick={() =>
                  setIsOpenOffertMobileDropdown(!isOpenOffertMobileDropdown)
                }
              >
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  height="1em"
                  viewBox="0 0 384 512"
                >
                  <path d="M342.6 150.6c12.5-12.5 12.5-32.8 0-45.3s-32.8-12.5-45.3 0L192 210.7 86.6 105.4c-12.5-12.5-32.8-12.5-45.3 0s-12.5 32.8 0 45.3L146.7 256 41.4 361.4c-12.5 12.5-12.5 32.8 0 45.3s32.8 12.5 45.3 0L192 301.3 297.4 406.6c12.5 12.5 32.8 12.5 45.3 0s12.5-32.8 0-45.3L237.3 256 342.6 150.6z" />
                </svg>
              </button>
              <div className="flex flex-wrap mt-10 gap-2 px-2 py-1 text-zinc-700 text-sm">
                {offertTypes.map((option) => (
                  <FilterSearch
                    key={option.id}
                    value={option.value}
                    handleFilter={handleValueOffertType}
                    typeFilter={offert}
                    icon={option.icon}
                  />
                ))}
              </div>
            </div>
          </div>
        )}
      </div>
    </div>
  )
}
