/** @format */

import { axiosApi } from "../utils/axiosInstance"
import { useEffect } from "react"
import { useState } from "react"
import { toast } from "react-toastify"
export default function UseGetProperties() {
  const [properties, setProperties] = useState([])
  const [loading, setLoading] = useState(true)
  const getRealStateProperties = async () => {
    try {
      const response = await axiosApi.get("/realState")
      if (response.status === 200) {
        const filteredProperties = response.data.filter(
          (property) => property.status === "available"
        )
        setProperties(filteredProperties)
      }
    } catch (error) {
      console.log(error)
      const errorMessage =
        error.message === "Network Error"
          ? "Please check your internet connection"
          : error.response?.data || "Something went wrong"
      toast.error(errorMessage)
    } finally {
      setLoading(false)
    }
  }
  useEffect(() => {
    getRealStateProperties()
  }, [])

  return { loading, properties }
}
