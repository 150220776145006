// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.slider {
  height: auto;
  width: 90%;
  position: relative;
  margin-top: 30px;
}

.switchLeft,
.switchRight {
  text-align: center;
  cursor: pointer;
  z-index: 3;
  transition: background-color 0.3s ease-in-out;
  border-radius: 100%;
  padding: 10px;
}

.switchLeft {
  position: absolute;
  left: -40px;
  top: 50%;
}
.switchRight {
  position: absolute;
  right: -40px;
  top: 50%;
}
.switchLeft:hover,
.switchRight:hover {
  background-color: rgba(0, 0, 0, 0.8);
  color: white;
}

.slider .sliderbox {
  width: auto;
  overflow: hidden;
  white-space: nowrap;
  text-align: center;
  display: flex;
  align-items: center;
  scroll-behavior: smooth;
}
.slider .item {
  min-width: 340px;
  max-height: 220px;
  margin: 5px;
  z-index: 2;
}

@media (max-width: 768px) {
  .slider {
    width: 85%;
  }
}

@media (max-width: 640px) {
  .slider {
    margin-top: 80px;
  }
}
`, "",{"version":3,"sources":["webpack://./src/styles/Carousel.css"],"names":[],"mappings":"AAAA;EACE,YAAY;EACZ,UAAU;EACV,kBAAkB;EAClB,gBAAgB;AAClB;;AAEA;;EAEE,kBAAkB;EAClB,eAAe;EACf,UAAU;EACV,6CAA6C;EAC7C,mBAAmB;EACnB,aAAa;AACf;;AAEA;EACE,kBAAkB;EAClB,WAAW;EACX,QAAQ;AACV;AACA;EACE,kBAAkB;EAClB,YAAY;EACZ,QAAQ;AACV;AACA;;EAEE,oCAAoC;EACpC,YAAY;AACd;;AAEA;EACE,WAAW;EACX,gBAAgB;EAChB,mBAAmB;EACnB,kBAAkB;EAClB,aAAa;EACb,mBAAmB;EACnB,uBAAuB;AACzB;AACA;EACE,gBAAgB;EAChB,iBAAiB;EACjB,WAAW;EACX,UAAU;AACZ;;AAEA;EACE;IACE,UAAU;EACZ;AACF;;AAEA;EACE;IACE,gBAAgB;EAClB;AACF","sourcesContent":[".slider {\n  height: auto;\n  width: 90%;\n  position: relative;\n  margin-top: 30px;\n}\n\n.switchLeft,\n.switchRight {\n  text-align: center;\n  cursor: pointer;\n  z-index: 3;\n  transition: background-color 0.3s ease-in-out;\n  border-radius: 100%;\n  padding: 10px;\n}\n\n.switchLeft {\n  position: absolute;\n  left: -40px;\n  top: 50%;\n}\n.switchRight {\n  position: absolute;\n  right: -40px;\n  top: 50%;\n}\n.switchLeft:hover,\n.switchRight:hover {\n  background-color: rgba(0, 0, 0, 0.8);\n  color: white;\n}\n\n.slider .sliderbox {\n  width: auto;\n  overflow: hidden;\n  white-space: nowrap;\n  text-align: center;\n  display: flex;\n  align-items: center;\n  scroll-behavior: smooth;\n}\n.slider .item {\n  min-width: 340px;\n  max-height: 220px;\n  margin: 5px;\n  z-index: 2;\n}\n\n@media (max-width: 768px) {\n  .slider {\n    width: 85%;\n  }\n}\n\n@media (max-width: 640px) {\n  .slider {\n    margin-top: 80px;\n  }\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
