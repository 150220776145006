import React from "react"
import "../../styles/components/ButtonWhatsapp.css"
export default function ButtonWhatsapp() {
  return (
    <a
      className="fixed bottom-10 right-5 sm:top-20 sm:right-10 lg:top-40 animate-whatsapp-logo"
      href="https://wa.link/vjkynf  "
    >
      <img
        className="w-14 h-14 md:w-16 md:h-16"
        width={64}
        height={64}
        src="/icons/contact/squareWhatsapp.svg"
        alt="Contac us on whatsapp"
      />
    </a>
  )
}
