import "../styles/Home.css"
export default function Home() {
  const servicesRealState = [
    {
      id: 1,
      url: "https://res.cloudinary.com/dpj4akaog/image/upload/v1694456154/Indoles/Inmobiliaria/IMG_9083_cqyn2l.webp",
      alt: "Lot for Sale Rincon Campestre Rivera Huila",
      title: "Lotes",
      link: "/Inmobiliaria",
      description:
        "Presentamos lotes bien ubicados con oportunidad de valorización en diferentes partes del país.",
    },
    {
      id: 2,
      url: "https://res.cloudinary.com/dpj4akaog/image/upload/v1689215943/Indoles/Servicios%20Inmobiliaria/Apartament_wzs9wk.webp",
      alt: "Apartaments for sale",
      title: "Apartamentos",
      link: "/Inmobiliaria",
      description: "Ofrecemos apartamentos tanto para la venta como para arrendar.",
    },
    {
      id: 3,
      url: "https://res.cloudinary.com/dpj4akaog/image/upload/v1689215943/Indoles/Servicios%20Inmobiliaria/House_kyapwi.webp",
      alt: "Houses for sale",
      title: "Casas",
      link: "/Inmobiliaria",
      description:
        "Ofrecemos vivienda cómoda, segura y adecuada para tu familia para la venta o arrendar.",
    },
  ]
  const servicesCivilEngineering = [
    {
      id: 1,
      alt: "Estructural Design",
      description:
        "Diseñamos estructuras para tu vivienda, tus proyectos comerciales e industriales cumpliendo con tus sueños y metas.",
      title: "Diseño Estructural",
      link: "/Diseño-estructural",
      url: "https://res.cloudinary.com/dpj4akaog/image/upload/v1689193877/Indoles/Servicios%20Ingenieria/estructuralDesign_qrcqtx.webp",
    },
    {
      id: 2,
      alt: "Consultory",
      description:
        " Acompañamiento en la consultoría e interventoría durante la construcción de tu proyecto de tu vivienda, tu local comercial e industrial protegiendo tu inversión.",
      link: "/Consultoría",
      title: "Consultoría",
      url: "https://res.cloudinary.com/dpj4akaog/image/upload/v1689198230/Indoles/Servicios%20Ingenieria/cosultory_w1gv4x.webp",
    },

    {
      id: 3,
      alt: "Costs and Budget",
      description:
        "Cotización de proyectos de obra civil, ayudándote a través de la elaboración de costos y presupuestos; programación de obra; haciendo realidad tus sueños de construir tu vivienda, local comercial, y facilidad industrial.",
      link: "/Costos-y-presupuestos",
      title: "Costos y Presupuestos",
      url: "https://res.cloudinary.com/dpj4akaog/image/upload/v1689207352/Indoles/Servicios%20Ingenieria/costsAndBudget_qvgrko.webp",
    },
    {
      id: 4,
      alt: "Building Permit",
      description:
        "El equipo profesional realiza los trámites para obtener la licencia de construcción de tu proyecto de tu vivienda, tu local comercial e industrial.",
      link: "/Licencias-de-construcción",
      title: "Licencias de Construcción",
      url: "https://res.cloudinary.com/dpj4akaog/image/upload/v1689197046/Indoles/Servicios%20Ingenieria/LicenceConstruction_w074mg.webp",
    },
    {
      id: 5,
      alt: "Construction",
      description:
        "Somos su aliado estratégico para la construcción de tu vivienda, tus proyectos comerciales e industriales.",
      link: "/",
      title: "Construcción",
      url: "https://res.cloudinary.com/dpj4akaog/image/upload/v1689208859/Indoles/Servicios%20Ingenieria/construction_albkeu.webp",
    },
    {
      id: 6,
      alt: "Maintence",
      description: "Realizamos el mantenimiento de tu vivienda, tu local comercial e industrial.",
      link: "/",
      title: "Mantenimiento",
      url: "https://res.cloudinary.com/dpj4akaog/image/upload/v1689210524/Indoles/Servicios%20Ingenieria/Maintence_ptlr4t.webp",
    },
  ]
  const services = [
    {
      id: 1,
      srcSet:
        "https://res.cloudinary.com/dpj4akaog/image/upload/v1690169895/Indoles/HomePage/Construction1280w_l6v75r.webp 1280w, https://res.cloudinary.com/dpj4akaog/image/upload/v1690233967/Indoles/HomePage/Construction640w_ljhhgu.webp 640w",
      alt: "Civil Engineering",
      title: "Ingeniería Civil",
      description:
        "Somos sus aliados y estamos comprometidos en ofrecer los servicios de diseño y construcción de proyectos de infraestructura, vivienda e industria; brindando la mejor experiencia a nuestros clientes.",
    },
    {
      id: 2,
      srcSet:
        "https://res.cloudinary.com/dpj4akaog/image/upload/v1694486013/LE_PARC_VISTA_EXT_4_1_z8uyj8.jpg",
      alt: "Real State",
      title: "Inmobiliaria",
      description:
        "Te ofrecemos diferentes opciones de inmuebles para venta y arrendamiento con la información que necesitas para tomar la mejor decisión.",
    },
    {
      id: 3,
      srcSet:
        "https://res.cloudinary.com/dpj4akaog/image/upload/v1690169896/Indoles/HomePage/E-commerce1280w_uulik7.webp 1280w, https://res.cloudinary.com/dpj4akaog/image/upload/v1690233759/Indoles/HomePage/E-commerce640w_npjqg8.webp 640w",
      alt: "E-commerce",
      title: "E-commerce",
      url: "https://comercio.indolesltda.com/",
      description:
        "En la tienda online te ofrecemos un portafolio de productos de elementos de protección personal para la seguridad industrial. Además de equipos en alquiler y materiales para la construcción de tus proyectos de ingenería. ",
    },
  ]
  const scrollToServices = () => {
    const services = document.getElementById("services")
    services.scrollIntoView({ behavior: "smooth" })
  }
  return (
    <main>
      <div className="relative">
        <picture>
          <source
            srcSet="https://res.cloudinary.com/dpj4akaog/image/upload/v1690170449/Indoles/HomePage/BgSlide1-1440w_kqvpfc.webp"
            media="(min-width: 640px)"
          />
          <source
            srcSet="https://res.cloudinary.com/dpj4akaog/image/upload/v1693490380/vertical-photo-crane-sunset_1_1_1_2_u4r60j.webp"
            media="(max-width: 640px)"
            width={640}
            height={520}
          />
          <img
            src="https://res.cloudinary.com/dpj4akaog/image/upload/v1690170449/Indoles/HomePage/BgSlide1-1440w_kqvpfc.webp"
            width="100%"
            alt="Background home"
          />
        </picture>
        <div className="absolute w-72 top-1/2 flex flex-col text-justify items-start left-5 gap-3 text-white sm:top-1/3 xl:left-[100px] xl:w-[480px] xl:gap-8 animate-slide-up">
          <h1 className="font-bold text-4xl text-left lg:text-[52px] ">INDOLES</h1>
          <p className="font-medium text-lg lg:font-bold lg:text-2xl">
            Somos su aliado clave para el diseño y la construcción de sus proyectos de Ingeniería.
          </p>
          <button
            className="flex w-48 h-10 justify-center items-center rounded-sm font-bold bg-greenLogo lg:text-lg text-sm xl:w-60 xl:h-14 xl:rounded hover:bg-opacity-80 transition-opacity ease-in duration-300"
            onClick={() => scrollToServices()}
          >
            Empieza ahora
          </button>
        </div>
      </div>
      <div className="grid place-items-center mx-8 my-12 gap-5 sm:grid-cols-2 xl:grid-cols-3 xl:mx-[100px]">
        {services.map((service) => (
          <div
            key={service.id}
            className="flex flex-col items-center h-[400px] gap-5 sm:w-[320px] md:w-[360px]  "
          >
            <img
              className="rounded shadow-lg "
              srcSet={service.srcSet}
              alt={service.alt}
              width={360}
              loading="lazy"
            />
            <h2 className="font-semibold text-xl">{service.title}</h2>
            <p className="font-normal text-sm text-justify">
              {service.description}
              {service.id === 3 && (
                <a className="text-blue-500" href={service.url} target="_blank" rel="noreferrer">
                  comercio.indolesltda.com
                </a>
              )}
            </p>
          </div>
        ))}
      </div>
      <div className="flex w-full py-4 justify-center bg-blueBgFooter ">
        <div className="grid py-2 gap-5 md:flex xl:gap-20 ">
          <div className="flex flex-col items-center text-white ">
            <span className="text-4xl font-bold">20</span>
            <p>Años de experiencia</p>
          </div>
          <div className="flex flex-col items-center text-white">
            <span className="text-4xl font-bold">70</span>
            <p>Proyectos completados</p>
          </div>
        </div>
      </div>
      <div className="flex justify-center w-full">
        <section
          className="grid items-center mx-8 my-12 gap-y-12 md:grid-cols-2 md:gap-x-5 xl:mx-[100px] xl:my-[100px]"
          id="services"
        >
          <article className="flex flex-col items-center gap-6 lg:items-start xl:w-[456px]">
            <h2 className="font-bold text-2xl text-center md:text-3xl">SERVICIOS QUE OFERTAMOS</h2>
            <p className="text-justify text-base">
              El área de desarrollo en Indoles engloba el sector de la Ingeniería Civil y la
              inmobiliaria. En cada uno de los servicios contamos con un equipo de trabajo con +20
              años de experiencia y experticia para ofrecer un servicio de calidad.
            </p>
            <a
              className="flex w-48 h-10 justify-center items-center rounded-sm font-bold bg-greenLogo text-white lg:text-lg text-sm xl:w-60 xl:h-14 xl:rounded hover:bg-opacity-80 transition-opacity ease-in duration-300 "
              href="/Contáctanos"
            >
              Contáctanos
            </a>
          </article>
          <div className="flex justify-center items-center">
            <img
              src="https://res.cloudinary.com/dpj4akaog/image/upload/v1690171788/Indoles/HomePage/collage1440w_ufu1bh.webp"
              className="shadow-lg"
              loading="lazy"
              width={600}
              height={588}
              alt="Services collage"
            />
          </div>
        </section>
      </div>
      <div className="flex flex-col mx-10 my-12 space-y-6 xl:mx-[100px] xl-:my-[100px] xl:space-y-10">
        <h2 className="flex flex-col items-center font-semibold text-lg md:text-2xl ">
          INGENIERÍA CIVIL
          <span className="w-[100px] h-1 bg-blueLogo"></span>
        </h2>
        {/* Map over the array of the civil engineering services */}
        <div className="grid place-items-center gap-5 sm:grid-cols-2 lg:grid-cols-3">
          {servicesCivilEngineering.map((service) => (
            <div
              key={service.id}
              className="flex flex-col items-center gap-5 h-[350px] xl:w-[340px] max-h-[400px] md:h-96 flex-1"
            >
              <div className="rounded bg-blueLogo max-w-[340px]">
                <a href={service.link}>
                  <div className="group relative overflow-hidden cursor-pointer">
                    <img
                      className="rounded-t"
                      src={service.url}
                      alt={service.alt}
                      loading="lazy"
                      width={340}
                      height={177}
                    />

                    <div className="absolute left-0 top-0 w-full h-full flex flex-col items-center justify-center group-hover:bg-black group-hover:bg-opacity-30 transition-colors ease-out duration-300">
                      <img
                        className="hidden group-hover:flex "
                        src="/icons/play.svg"
                        alt="play icon"
                        width={48}
                        height={48}
                        loading="lazy"
                      />
                    </div>
                  </div>
                </a>
                <h3 className="px-2 my-1 text-white text-base font-medium">{service.title}</h3>
              </div>
              <p className="text-sm text-justify">{service.description}</p>
            </div>
          ))}
        </div>
        <h2 className="flex flex-col items-center font-semibold text-lg md:text-2xl">
          INMOBILIARIA
          <span className="w-[100px] h-1 bg-yellowLogo"></span>
        </h2>
        {/* Map over the array of the real state services */}
        <div className="grid place-items-center gap-5 sm:grid-cols-2 lg:grid-cols-3">
          {servicesRealState.map((service) => (
            <div
              key={service.id}
              className="flex flex-col items-center gap-5 h-[350px] xl:w-[340px] max-h-[400px] md:h-96 flex-1"
            >
              <div className="rounded bg-yellowLogo  max-w-[340px]">
                <a href={service.link}>
                  <div className="group relative overflow-hidden cursor-pointer">
                    <img
                      className="rounded-t h-[210px]"
                      src={service.url}
                      alt={service.alt}
                      loading="lazy"
                      width={340}
                      height={177}
                    />

                    <div className="absolute left-0 top-0 w-full h-full flex flex-col items-center justify-center group-hover:bg-black group-hover:bg-opacity-30 transition-colors ease-out duration-300">
                      <img
                        className="hidden group-hover:flex "
                        src="/icons/play.svg"
                        alt="play icon"
                        width={48}
                        height={48}
                        loading="lazy"
                      />
                    </div>
                  </div>
                </a>
                <h3 className="px-2 my-1 text-base font-medium">{service.title}</h3>
              </div>
              <p className="text-sm text-justify">{service.description}</p>
            </div>
          ))}
        </div>
      </div>
    </main>
  )
}
