/** @format */
import "../../styles/InfiniteCarousel.css"
import { useEffect, useRef } from "react"
import { useNavigate } from "react-router-dom"
export default function Carouselnfinite({ properties }) {
  const carouselRef = useRef(null)
  const navigate = useNavigate()
  const navigateToPropertyDetailsPage = (id) => {
    navigate(`/Inmobiliaria/Inmueble/Detalles?id=${id}`)
    window.scrollTo(0, 0)
  }
  useEffect(() => {
    let isDragging = false
    let startX
    let startY
    const carousel = carouselRef.current
    const arrowBtns = document.querySelectorAll(".carousel i")
    const handleArrowButtonClick = (btn) => {
      carousel.scrollLeft += btn.id === "left" ? -350 : 350
    }

    const dragStart = (e) => {
      isDragging = true
      carousel.classList.add("dragging")
      startX = e.pageX
      startY = e.pageY
    }
    const dragging = (e) => {
      if (!isDragging) return
      const diffX = e.pageX - startX
      const diffY = e.pageY - startY
      if (Math.abs(diffX) > Math.abs(diffY) && diffX > 0) {
        carousel.scrollLeft += -340
      } else {
        carousel.scrollLeft += 340
      }
    }
    const dragStop = () => {
      isDragging = false
      carousel.classList.remove("dragging")
    }
    arrowBtns.forEach((btn) => btn.addEventListener("click", () => handleArrowButtonClick(btn)))
    carousel.addEventListener("mousemove", dragging)
    carousel.addEventListener("mousedown", dragStart)
    document.addEventListener("mouseup", dragStop)
    return () => {
      arrowBtns.forEach((btn) =>
        btn.removeEventListener("click", () => handleArrowButtonClick(btn))
      )
      carousel.removeEventListener("mousemove", dragging)
      carousel.removeEventListener("mousedown", dragStart)
      document.removeEventListener("mouseup", dragStop)
    }
  }, [])
  return (
    <div className="carousel">
      <div className="carouselbox" ref={carouselRef}>
        {properties.map((property) => (
          <section
            className="item group relative rounded overflow-hidden cursor-pointer shadow-sm "
            draggable="false"
            key={property._id}
          >
            <img src={property.media.imageCard.url} alt="Card Propertie" width={340} height={227} />
            <div className="absolute left-4 top-4 px-4 py-1 rounded-sm bg-grayBgSlider bg-opacity-80 text-yellowLogo font-medium ">
              {property.usage?.length > 0
                ? property.usage.map((usage) => usage).join(", ")
                : property.usage[0]}
            </div>
            <div className="absolute left-1/2 bottom-4 transform -translate-x-1/2 px-2 flex flex-col items-start w-11/12 rounded-sm bg-grayBgSlider bg-opacity-80 ">
              <span className="text-yellowLogo font-medium">{`${property.propertyType} en ${property.address.neighborhood}`}</span>
              <div className="flex w-full justify-between font-medium">
                <span className="text-white ">{`${property.address.city} ${property.address.state}`}</span>
                <span className="text-yellowLogo">{`$ ${property.data.price.toLocaleString()}`}</span>
              </div>
            </div>
            <div className="absolute inset-0 flex flex-col items-center justify-center translate-y-[60%] group-hover:translate-y-0 group-hover:bg-black group-hover:bg-opacity-30 transition-all ease-in  ">
              <img
                src="/icons/play.svg"
                alt="Play button"
                width={48}
                height={48}
                onClick={() => navigateToPropertyDetailsPage(property._id)}
              />
            </div>
          </section>
        ))}
      </div>
      <i className="switchLeft" id="left">
        <svg
          xmlns="http://www.w3.org/2000/svg"
          fill="none"
          viewBox="0 0 24 24"
          strokeWidth={1.5}
          stroke="currentColor"
          className="w-4 h-4 xl:w-6 xl:h-6"
        >
          <path strokeLinecap="round" strokeLinejoin="round" d="M15.75 19.5L8.25 12l7.5-7.5" />
        </svg>
      </i>
      <i className="switchRight" id="right">
        <svg
          xmlns="http://www.w3.org/2000/svg"
          fill="none"
          viewBox="0 0 24 24"
          strokeWidth={1.5}
          stroke="currentColor"
          className="w-4 h-4 xl:w-6 xl:h-6"
        >
          <path strokeLinecap="round" strokeLinejoin="round" d="M8.25 4.5l7.5 7.5-7.5 7.5" />
        </svg>
      </i>
    </div>
  )
}
