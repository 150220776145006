/** @format */
import React from "react"
import ButtonPage from "../components/Buttons/ButtonPage"
import { useMediaQuery } from "react-responsive"
export default function BuildingPermit() {
  const isMobile = useMediaQuery({ maxWidth: 400 })
  const licences = [
    {
      id: 1,
      name: "Ampliación",
      description:
        "Esta licencia se tramita para aumentar el área construida en edificaciones existentes que cuenten con su licencia construcción previamente",
      url: "https://res.cloudinary.com/dpj4akaog/image/upload/v1688742000/Indoles/Licencias/extension_d1wrie.webp",
    },
    {
      id: 2,
      name: "Modificación",
      description:
        " Es la autorización para variar el diseño arquitéctonico o estructural de una edificación existente, sin incrementar su área construida",
      url: "https://res.cloudinary.com/dpj4akaog/image/upload/v1688742138/Indoles/Licencias/modification_yzfxhe.webp",
    },
    {
      id: 3,
      name: "Adecuación",
      description:
        "Es la autoriazación para cambiar el uso de una edificación o parte de ella (comercial, vivienda, industrial o institucional), garantizando la permanencia total o parcial del inmueble original",
      url: "https://res.cloudinary.com/dpj4akaog/image/upload/v1688743531/Indoles/Licencias/adecuacy_nbscpr.webp",
    },
    {
      id: 4,
      name: "Obra Nueva",
      description:
        "Es la autorización para adelantar obras de edifiación en terrenos no construidos o cuya área esté libre por autorización de demolición total",
      url: "https://res.cloudinary.com/dpj4akaog/image/upload/v1688742659/Indoles/Licencias/newConstruction_mtebnk.webp",
    },
    {
      id: 5,
      name: "Reforzamiento Estructural",
      description:
        "Es la autorización para intervenir o refozar la estructura de uno o varios inmuebles, con el objeto de acondicionarlos a condiciones a niveles adecuados de seguridad sismoresistente",
      url: "https://res.cloudinary.com/dpj4akaog/image/upload/v1688742778/Indoles/Licencias/reinforcementEstructural_fxmxdp.webp",
    },
    {
      id: 6,
      name: "Reconstrucción",
      description:
        "Es la autorización que se otorga para volver a construir edificaciones que contaban con licencia o con acto de reconocimiento y que fueron afectadas por la ocurrencia de algun siniestro",
      url: "https://res.cloudinary.com/dpj4akaog/image/upload/v1688742924/Indoles/Licencias/reconstruction_ojjy5b.webp",
    },
  ]
  const requirementsForLicences = [
    {
      id: 1,
      name: "Certificado de Paramento",
    },
    {
      id: 2,
      name: "Certificado de Estratificación",
    },
    {
      id: 3,
      name: "Certificado de Uso Suelo Predio",
    },

    {
      id: 4,
      name: "Certificado de Tradición",
    },
    {
      id: 5,
      name: "Plano Catastral del Inmueble",
    },
  ]
  return (
    <main>
      <div className="relative w-full">
        <img
          className="w-full h-full"
          src={
            isMobile
              ? "https://res.cloudinary.com/dpj4akaog/image/upload/v1688765373/Indoles/bgBuildingPermitMobile_zr0kbn.webp"
              : "https://res.cloudinary.com/dpj4akaog/image/upload/v1688765370/Indoles/bgBuildingPermit_geciag.webp"
          }
          alt="Solicita tu licencia de construcción"
        />
        <h1 className="absolute top-2/3 left-1/3 flex flex-row-reverse transform -translate-x-1/3 -translate-y-1/3 gap-2.5 font-bold text-2xl text-left text-white sm:top-1/2 sm:left-1/2 sm:flex-col sm:items-center sm:-translate-x-1/2 sm:translate-y-1/2 lg:text-[42px]">
          ADQUIERE TU LICENCIA
          <span className="w-1 h-[60px] bg-blueLogo sm:w-[100px] sm:h-1"></span>
        </h1>
      </div>
      <div className="grid grid-cols-1 mx-10 my-12 gap-6 sm:grid-cols-2 lg:my-[100px] lg:mx-[100px]">
        <section className="flex justify-center md:justify-start">
          <article className="flex flex-col items-center gap-9 md:w-80 lg:w-[453px] md:items-start">
            <h2 className=" text-center text-2xl font-bold md:text-left lg:text-[32px]">
              TRAMITAMOS TU LICENCIA
            </h2>
            <p className="text-justify text-sm lg:text-base">
              Realizamos el debido acompañamiento en el proceso de solicitud de
              la licencia de construcción. En el equipo de trabajo contamos con
              personal experto para la ejecución de cada una de las tareas
            </p>
            <p className="text-justify text-sm text-grayDark lg:text-base">
              Somos expertos en el desarrollo de licencias de construcción a
              nivel nacional.
              <img
                className="w-6 h-6"
                src="/icons/flagColombia.svg"
                alt="El pais Colombia"
              />
            </p>
            <ButtonPage />
          </article>
        </section>
        <div className="flex justify-center sm:items-center">
          <img
            className="shadow-lg rounded"
            src="https://res.cloudinary.com/dpj4akaog/image/upload/v1688772535/Indoles/managerIndoles_d2gtna.webp"
            alt="Manager Indoles LTDA"
            width={520}
            height={365}
            loading="lazy"
          />
        </div>
      </div>
      <div className="relative my-12 mx-10 lg:my-[100px] lg:mx-[100px]">
        <h2 className="text-center text-2xl font-bold md:text-left lg:text-[32px]">
          MODALIDADES LICENCIAS DE CONSTRUCCIÓN
        </h2>
        <ul className="grid gap-y-4 mt-12 md:mt-9 md:grid-cols-3 lg:mt-16 ">
          {/*Building permit modalities*/}
          {licences.map((licence) => (
            <li key={licence.id} className="flex w-full justify-center">
              <div className="flex flex-col items-center gap-5 sm:w-52 lg:w-60 lg:h-[335px] xl:w-72 ">
                <h3 className="text-center font-semibold">{licence.name}</h3>
                <img
                  src={licence.url}
                  className="rounded"
                  width={263}
                  height={175}
                  alt={licence.name}
                  loading="lazy"
                />
                <p className="text-justify text-sm">{licence.description}</p>
              </div>
            </li>
          ))}
        </ul>
      </div>
      {/*Documentation for the process*/}
      <div className="grid mx-10 my-12 gap-6 sm:grid-cols-2 lg:my-[100px] lg:mx-[100px]">
        <div className="flex justify-center sm:justify-start">
          <div className="flex flex-col gap-3 md:w-[500px] md:gap-5">
            <h2 className=" text-center text-2xl font-bold md:text-start lg:text-[32px]">
              DOCUMENTACIÓN PARA EL PROCESO
            </h2>
            <p className="text-justify text-sm lg:text-base">
              Para toda licencia de construcción se solicitan los siguientes
              documentos. De lo demas nos encargamos nostros.
            </p>
            <ul className="flex flex-col mt-2 gap-3.5 text-[12px] md:gap-5 md:text-sm">
              {requirementsForLicences.map((requirement) => (
                <li key={requirement.id} className="inline-flex gap-2.5">
                  <img
                    className="w-6 h-6"
                    src="/icons/circleCheckSolid.svg"
                    alt="Check icon"
                  />
                  {requirement.name}
                </li>
              ))}
            </ul>
            <ButtonPage />
          </div>
        </div>
        <div className="flex justify-center items-center">
          <img
            className=" shadow-2xl rounded"
            src="https://res.cloudinary.com/dpj4akaog/image/upload/v1688772830/Indoles/Licencias/licence_fblsaw.webp"
            alt="Licencia de Construcción"
            loading="lazy"
          />
        </div>
      </div>
    </main>
  )
}
