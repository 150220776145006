export default function FormInput({
  name,
  label,
  register,
  type,
  errors,
  placeholder,
  rules,
  width,
}) {
  return (
    <div className="flex flex-col gap-3">
      <label className="font-medium text-base" htmlFor={label}>
        {name}
      </label>
      <input
        className={`h-[55px] px-2 border border-grayTextConsultory rounded  ${width}`}
        id={label}
        type={type}
        placeholder={placeholder}
        {...register(label, {
          ...rules,
        })}
      />
      {errors[label]?.type === "required" && (
        <span className="text-red-700">Este campo es requerido</span>
      )}
    </div>
  )
}
