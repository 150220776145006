export default function AboutUs() {
  return (
    <main className="container">
      <div className="w-full h-full">
        <div className="h-[500px] max-h-[600px] relative xl:h-[600px]">
          <div className="absolute w-full h-full flex flex-col items-center justify-start mt-12 gap-3 ">
            <h1 className="text-3xl text-center text-white font-semibold sm:text-4xl sm:text-left lg:text-[52px]">
              QUIENES SOMOS
            </h1>
            <span className="w-[100px] h-1 bg-yellowLogo" />
          </div>
          <img
            className="w-full h-full object-cover"
            srcSet="https://res.cloudinary.com/dpj4akaog/image/upload/v1688762699/Indoles/bgAboutUs_huoyeg.webp 1280w,
              https://res.cloudinary.com/dpj4akaog/image/upload/v1690060738/Indoles/Nosotros/BGAboutUs640_sq1qsb.webp 640w"
            sizes='sizes="(max-width: 640px) 100vw, 100vw"'
            alt="Work Team Indoles LTDA"
          />
        </div>
      </div>
      {/*Section description about the enterprise */}
      <div className="grid grid-cols-1 gap-6 mx-8 my-12 md:grid-cols-2 lg:mx-[100px] lg:my-[100px] ">
        <section className="flex flex-col items-center md:items-start xl:col-span-1 ">
          <article className="flex flex-col w-64 items-center gap-5 sm:w-[400px] md:w-[300px] md:items-start lg:w-[395px]  ">
            <h2 className="text-2xl font-bold">INDOLES LTDA</h2>
            <span className="w-[100px] h-1 bg-yellowLogo"></span>
            <p className="text-justify text-sm lg:text-base">
              Somos su aliado clave para el diseño y la construcción de sus proyectos de Ingeniería,
              comprometidos en mejorar la seguridad industrial en los proyectos de construcción de
              nuestros clientes.
            </p>
            <a
              href="/Contáctanos"
              className="flex w-64 h-14 rounded-sm bg-yellowLogo justify-center items-center text-base font-bold "
            >
              CONTACTENOS
            </a>
          </article>
        </section>
        <section className="flex justify-center md:items-end ">
          <img
            src="https://res.cloudinary.com/dpj4akaog/image/upload/v1688762002/Indoles/workTeam_y1zceq.webp"
            className="w-auto h-auto rounded-sm lg:rounded"
            alt="Wor Team Indoles LTDA"
            loading="lazy"
            width="100%"
            height="100%"
          />
        </section>
      </div>
      {/*Section mision and vision*/}
      <div className="grid grid-cols-1 gap-6 mx-8 my-12 md:grid-cols-2 lg:mx-[100px] lg:my-[100px] ">
        <section className="flex justify-center items-center lg:justify-start">
          <img
            className="w-auto h-auto rounded-sm lg:rounded"
            src="https://res.cloudinary.com/dpj4akaog/image/upload/v1688762337/Indoles/workTeam2_nnj9f9.webp"
            alt="Work Team Indoles LTDA"
            loading="lazy"
            width="100%"
            height="100%"
          />
        </section>
        <section className="flex flex-col items-center gap-5 sm:gap-12 md:items-start">
          <article className="flex flex-col w-64 items-center gap-5 sm:w-[400px] md:w-[300px] lg:w-[400px] xl:w-[523px] md:items-start">
            <h2 className="text-2xl font-bold">MISIÓN</h2>
            <span className="w-[100px] h-1 bg-yellowLogo"></span>
            <p className="text-justify overflow-hidden lg:text-base">
              Apoyar con soluciones integrales, los proyectos de ingeniería (infraestructura,
              vivienda, e industrial), somos expertos en la planificación, construcción,
              interventorías, consultorías, diseño estructural, costos y presupuestos de obras
              civiles, solicitud de licencias de construcción. Además, ofrecemos un portafolio de
              elementos para la protección personal y seguridad industrial, materiales para la
              construcción, alquiler de equipo y maquinaria.
            </p>
          </article>
          <article className="flex flex-col w-64 items-center gap-5 sm:w-[400px] md:w-[300px] lg:w-[400px] xl:w-[523px] md:items-start ">
            <h2 className="text-2xl font-bold">VISIÓN</h2>
            <span className="w-[100px] h-1  bg-yellowLogo"></span>
            <p className="text-justify overflow-hidden lg:text-base">
              Nos proyectamos para el año 2030, ser reconocidos nacional e internacionalmente como
              un aliado estratégico, inversionista, constructor y consultor, diseñador estructural
              en proyectos de Ingeniería Civil, en diversos sectores de la economía, así mismo
              Ingeniería y Dotaciones Industriales Ltda. – Indoles Ltda., se ve a sí misma como una
              empresa reconocida en todo el país, a través de su Ecommerce {""}
              {
                <a
                  className="lg:text-base text-blue-500"
                  href="https://comercio.indolesltda.com/"
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  comercio.indolesltda.com
                </a>
              }
              , mejorando la seguridad industrial, calidad, procesos de construcción de obras
              civiles en Colombia.
            </p>
          </article>
        </section>
      </div>
      {/*Section trayectory*/}
      <div className="grid grid-cols-1 justify-center gap-5 mx-8 my-12  md:grid-cols-2 lg:mx-[100px] lg:my-[100px]">
        <section className="flex flex-col items-center gap-5 md:items-start">
          <h2 className="text-2xl font-bold ">TRAYECTORIA</h2>
          <span className="w-[100px] h-1 bg-yellowLogo"></span>
          <article className="flex flex-col items-center w-64 gap-3 sm:w-[400px] md:w-[300px] md:items-start lg:w-[400px] xl:w-[530px]  ">
            <span className=" w-32 h-10 px-6 py-3 border border-yellowLogo text-center md:w-36 md:h-12">
              2006
            </span>
            <h3 className="font-semibold text-base">Constitución de la empresa</h3>
            <p className="font-normal text-justify text-sm lg:text-base">
              Constituida en el mes de de Junio de 2006 en la ciudad de Bogotá. Esta empresa surgió
              teniendo en cuenta las multiples necesidades insatisfechas en el momento, por las
              empresas de ingeniería de la ciudad de Bogotá y Neiva, en cuanto al apoyo en servicios
              de Ingeniería Civil y el abastecimiento de elementos de protección personal seguridad
              industrial y confección de uniformes empresariales
            </p>
          </article>
          <article className="flex flex-col items-center w-64 gap-3 sm:w-[400px] md:w-[300px] md:items-start lg:w-[400px] xl:w-[530px]">
            <span className="w-32 h-10 px-6 py-3 border border-yellowLogo text-center md:w-36 md:h-12">
              2023
            </span>
            <h3 className="font-semibold text-base">Lanzamiento de la aplicación web</h3>
            <p className="font-normal text-justify text-sm lg:text-base">
              A finales de 2023 se realiza el lanzamiento de la aplicación web de la empresa. En la
              plataforma se ofertan servicios de Ingeniería Civil, inmobiliaria y el comercio
              electrónico para la venta de elementos de protección personal y alquiler de maquinaria
            </p>
          </article>
        </section>
        <div className="flex justify-center items-center ">
          <div className="flex items-center">
            <section className="flex justify-center items-center w-64 h-80 rounded-sm bg-yellowLogo bg-opacity-80 border border-black border-opacity-75 shadow-md hover:bg-yellowLogo hover:bg-opacity-80 cursor-pointer md:rounded lg:w-[344px] lg:h-[455px]">
              <article className="flex flex-col w-52 gap-6 ">
                <h3 className="text-center text-base font-semibold ">ÚNETE A NOSOTROS</h3>
                <p className="flex-justify overflow-hidden text-sm">
                  Somos una de las empresas líder en el sector de la Ingeniería Civil. Registrate
                  para disfrutar de todos los servicios.
                </p>
                <a
                  className="flex w-full h-10 justify-center items-center rounded border border-black border-opacity-60 font-medium hover:bg-white transition-all ease-in"
                  href="/oferta-laboral"
                >
                  UNIRSE AHORA
                </a>
              </article>
            </section>
          </div>
        </div>
      </div>
    </main>
  )
}
