/** @format */

import { useState, useEffect } from "react"
import { axiosApi } from "../utils/axiosInstance"
import { toast } from "react-toastify"
export default function UseGetFilteredProperty({ city, offert, type }) {
  const [propertiesFiltered, setPropertiesFiltered] = useState([])
  const [loading, setLoading] = useState(true)
  useEffect(() => {
    const getFilteredProperties = async () => {
      try {
        const response = await axiosApi.get(
          `/realState/property?city=${city}&type=${type}&offert=${offert}`
        )
        const { data, status } = response
        const filteredData = data.filter(
          (property) => property.status === "available"
        )
        if (status === 200) {
          setPropertiesFiltered(filteredData)
        }
      } catch (error) {
        console.log(error)
        const errorMessage =
          error.response === "Network Error"
            ? "Please check your internet connection"
            : error.response?.data || "Something went wrong"
        toast.error(errorMessage)
      } finally {
        setLoading(false)
      }
    }
    getFilteredProperties()
  }, [type, offert, city])

  return {
    loading,
    propertiesFiltered,
  }
}
