import React from "react"

export default function CheckBox({ label, errors, rules, register }) {
  return (
    <div className="flex flex-col gap-3 ">
      <div className="inline-flex gap-2 items-center">
        <input
          className="outline-none w-5 h-5 cursor-pointer"
          type="checkbox"
          id="privacyPolice"
          {...register("termsOfUse", { ...rules })}
        />
        <label className="text-sm font-medium" htmlFor="privacyPolice">
          Acepta los{" "}
          <a
            className="text-blue-600 underline"
            href="/política-de-protección-de-datos-personales"
            target="_blank"
            rel="noopener noreferrer"
          >
            términos de uso y la política de tratamiento de datos personales
          </a>
        </label>
      </div>
      {errors[label]?.type === "required" && (
        <span className="text-red-700">Este campo es requerido</span>
      )}
    </div>
  )
}
