import React from "react"

export const ButtonSendForm = () => {
  return (
    <button
      type="submit"
      className="w-full h-14 rounded bg-greenLogo bg-opacity-90 text-white font-bold hover:bg-opacity-0 hover:text-greenLogo hover:border-2 hover:border-greenLogo transition-all duration-200 ease-in"
    >
      Enviar
    </button>
  )
}

export default ButtonSendForm
