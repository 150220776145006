import React from "react"
import WorkWithUs from "../components/Forms/WorkWithUs"
import ButtonWhatsapp from "../components/Buttons/ButtonWhatsapp"
export default function Job() {
  return (
    <div className="flex flex-col w-full py-12 px-10 justify-center items-center space-y-5 xl:my-[100px] sm:px-0">
      <h2 className="font-bold text-center text-3xl text-zinc-700 ">
        TRABAJA CON NOSOTROS
      </h2>
      <p className="px-4 text-zinc-600  text-justify lg:w-[600px]">
        Para ser parte de la empresa líder en el sector de la Ingeniería Civil
        déjanos saber, envía la siguiente documentación. Te contactaremos si
        tienes el perfil que estamos buscando.👷🏗️
      </p>
      <ButtonWhatsapp />
      <WorkWithUs />
    </div>
  )
}
