/** @format */
import ButtonWhatsapp from "../components/Buttons/ButtonWhatsapp"
import { Pqrs } from "../components/Forms/Pqrs"

export default function ContactUs() {
  return (
    <main>
      <div className="relative">
        <div className="absolute w-full h-full flex flex-col items-center justify-center gap-2 md:items-start md:ml-[100px]">
          <h1 className="text-3xl text-center text-white font-bold sm:text-left sm:text-4xl lg:text-5xl ">
            ESTAMOS PARA TI
          </h1>
          <p className=" font-medium text-lg text-justify text-white sm:text-2xl sm:font-semibold ">
            Nos interesa tu opinión
          </p>
        </div>
        <picture>
          <source
            srcSet="https://res.cloudinary.com/dpj4akaog/image/upload/v1689689471/Indoles/Contactanos/BgContactUs1440_dggwmr.webp"
            media="(min-width: 640px)"
            sizes="(min-width: 640px) 100vw, 1440px"
          />
          <source
            srcSet="https://res.cloudinary.com/dpj4akaog/image/upload/v1689983496/Indoles/Contactanos/Bg_bftzdq.webp"
            media="(max-width: 640px)"
            sizes="(max-width: 640px) 100vw, 640px"
          />
          <img
            src="https://res.cloudinary.com/dpj4akaog/image/upload/v1694452889/Indoles/Inmobiliaria/bgInmobiliaria_w1280_qm0t11_1_nroobv.webp"
            alt="Background contact us"
            width="100%"
            height={600}
            className="object-cover"
          />
        </picture>
      </div>
      <div className="flex justify-center mx-10 my-12 lg:my-[100px] ">
        <div className="flex flex-col gap-5  md:flex-row md:gap-10 md:mt-10 ">
          <div className="flex flex-col items-center gap-5">
            <img width={64} height={64} src="/icons/clock.svg" alt="clock" loading="lazy" />
            <span className="font-medium ">Horarios</span>
            <span className="text-center font-medium text-base text-grayBgSlider">
              8:00am - 12:00pm <br /> 2:00pm - 6:00pm
            </span>
          </div>
          <div className="flex flex-col items-center gap-5 md:-translate-y-10">
            <img
              width={64}
              height={64}
              src="/icons/contact/smartphone.svg"
              alt="smartphone"
              loading="lazy"
            />
            <span className="font-medium">Linea de atención</span>
            <span className="text-center font-medium text-base text-grayBgSlider">
              Linea única: 3186081940
            </span>
          </div>
          <div className="flex flex-col items-center gap-5">
            <img width={64} height={64} src="/icons/contact/email.svg" alt="email" loading="lazy" />
            <span className="font-medium">Email</span>
            <span className="text-center font-medium text-base text-grayBgSlider">
              indolesltda@gmail.com
            </span>
          </div>
        </div>
      </div>
      <div className="flex justify-center mx-10 my-12 lg:my-[100px] ">
        <div className="flex flex-col items-center gap-5">
          <h2 className="font-bold text-2xl md:text-3xl">FORMULARIO PQRS</h2>
          <p className="w-80 text-justify font-medium text-sm text-grayBgSlider sm:text-base sm:w-[600px]">
            Las solicitudes serán tramitadas en los horarios establecidos de atención al ciudadano
            (lunes a viernes de 8:00am-12:00pm 2:00pm-6:00pm). En caso de que la recepción se diera
            en horarios no hábiles, se dará tramite el día habil siguiente a la recepción.
          </p>
          <Pqrs />
        </div>
      </div>
      <ButtonWhatsapp />
    </main>
  )
}
