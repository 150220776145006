/** @format */

import { useState, useEffect } from 'react'
import { axiosApi } from '../utils/axiosInstance'
export default function UseGetPropertyById(id) {
  const [propertyDetails, setPropertyDetails] = useState([])
  const [loading, setLoading] = useState(true)
  useEffect(() => {
    const getPropertyById = async () => {
      try {
        const response = await axiosApi.get(
          `/realState/property/propertyDetails?id=${id}`
        )
        setPropertyDetails(response.data)
        setLoading(false)
      } catch (error) {
        console.log(error)
      }
    }
    getPropertyById()
  }, [id])

  return { loading, propertyDetails }
}
