/** @format */

import { useState } from "react"
export default function Carousel({ media }) {
  const [position, setPosition] = useState(0)
  const handleNextSlide = () => {
    const isLastSlide = position === slides.length - 1
    const newIndex = isLastSlide ? 0 : position + 1
    setPosition(newIndex)
  }
  const handlePrevSlide = () => {
    const isFirstSlide = position === 0
    const newIndex = isFirstSlide ? slides.length - 1 : position - 1
    setPosition(newIndex)
  }
  const slides = media.video.url
    ? [media.video.url, ...media.imageSlider.map((image) => image.url)]
    : media.imageSlider.map((image) => image.url)

  return (
    <div className="relative flex justify-center rounded-lg bg-grayBgSlider bg-opacity-20 shadow-md xl:w-[1000px] xl:h-[400px] ">
      {slides[position] === media.video.url ? (
        <video
          controls
          width={800}
          height={400}
          src={slides[position]}
          alt={slides[position]?.description || "Video"}
        />
      ) : (
        <img
          width={800}
          height={400}
          src={slides[position]}
          alt={slides[position]?.description || "Image"}
        />
      )}

      <img
        className="absolute top-[50%] -translate-x-0 translate-y-[-50%] left-[25px] w-[50px] h-[50px] rotate-180 cursor-pointer"
        src="/icons/play.svg"
        alt="Play button"
        onClick={() => handlePrevSlide()}
      />
      <img
        className="absolute top-[50%] -translate-x-0 translate-y-[-50%] right-[25px] w-[50px] h-[50px] cursor-pointer"
        src="/icons/play.svg"
        alt="Play button"
        onClick={() => handleNextSlide()}
      />
    </div>
  )
}
