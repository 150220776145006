import React from "react"

export const FeaturesProperty = ({ features, label }) => {
  return (
    <section className="flex flex-col gap-3.5">
      <h2 className="font-medium text-xl">{label}</h2>
      <span className="w-full h-1 bg-yellowLogo"></span>
      <div className="grid grid-cols-2 gap-10">
        <ul className="flex flex-col gap-2">
          {features
            ?.slice(0, Math.round(features.length / 2))
            .map((feature, index) => (
              <li className="text-sm md:text-base" key={index}>
                {feature}
              </li>
            ))}
        </ul>
        <ul className="flex flex-col gap-2">
          {features
            ?.slice(Math.round(features.length / 2), features.length)
            .map((feature, index) => (
              <li className="text-sm md:text-base" key={index}>
                {feature}
              </li>
            ))}
        </ul>
      </div>
    </section>
  )
}
