import React from "react"
import { useForm } from "react-hook-form"
import { ButtonSendForm } from "../Buttons/ButtonSendForm"
import FormInput from "../Fields/FormInput"
import CheckBox from "../Fields/CheckBox"
import { axiosApi } from "../../utils/axiosInstance"
import { toast } from "react-toastify"
export default function Quote() {
  const {
    handleSubmit,
    register,
    reset,
    setValue,
    watch,
    formState: { errors },
  } = useForm()

  const values = watch()
  const submitQuote = async (data) => {
    try {
      const response = await axiosApi.post("/forms/quote", data)
      if (response.status === 200) {
        toast.success("Cotización enviada con éxito")
        reset()
      }
    } catch (error) {
      console.log(error)
      const errorMessage =
        error.message === "Network Error"
          ? "Error de conexión"
          : error.response.data.message || error.response.data
      toast.error(errorMessage)
    }
  }
  return (
    <form
      className="flex flex-col items-center gap-5 sm:w-[600px] sm:items-start sm:gap-5"
      onSubmit={handleSubmit(submitQuote)}
    >
      {/*Kind of procedure*/}
      <div className="flex flex-col w-full gap-3">
        <label className="font-medium text-base" htmlFor="license">
          Tipo de trámite *
        </label>
        <select
          className="h-[55px] border border-grayTextConsultory rounded cursor-pointer"
          id="license"
          defaultValue=""
          onChange={(e) => setValue("typeOfIdentification", e.target.value)}
          {...register("kindOfProcedure", { required: true })}
        >
          <option value="" disabled>
            Seleccione
          </option>
          <option value="Licencia de urbanización">Licencia de urbanización</option>
          <option value="Licencia de parcelación">Licencia de parcelación</option>
          <option value="Licencia de subdivisión">Licencia de subdivisión</option>
          <option value="Licencia de construcción">Licencia de construcción</option>
          <option value="Intervención y ocupación del espacio público">
            Intervención y ocupación del espacio público
          </option>
          <option value="Reconocimiento de la existencia de una edificación">
            Reconocimiento de la existencia de una edificación
          </option>
          <option value="Otras actividades">Otras actividades</option>
        </select>
        {errors.kindOfProcedure?.type === "required" && (
          <span className="text-red-700">Este campo es requerido</span>
        )}
      </div>
      {watch("kindOfProcedure") === "Licencia de construcción" && (
        <div className="flex flex-col w-full gap-3">
          <label className="font-medium text-base" htmlFor="license">
            Modalidad licencia de construcción *
          </label>
          <select
            className="h-[55px] border border-grayTextConsultory rounded cursor-pointer"
            id="license"
            defaultValue=""
            onChange={(e) => setValue("typeOfIdentification", e.target.value)}
            {...register("typeOfLicense", { required: true })}
          >
            <option value="" disabled>
              Seleccione
            </option>
            <option value="Obra nueva">Obra nueva</option>
            <option value="Ampliación">Ampliación</option>
            <option value="Adecuación">Adecuación</option>
            <option value="Modificación">Modificación</option>
            <option value="Restauración">Restauración</option>
            <option value="Reforzamiento estructural">Reforzamiento estructural</option>
            <option value="Demolición">Demolición</option>
            <option value="Reconstrucción">Reconstrucción</option>
            <option value="Cerramiento">Cerramineto</option>
          </select>
          {errors.typeOfLicense?.type === "required" && (
            <span className="text-red-700">Este campo es requerido</span>
          )}
        </div>
      )}
      {watch("typeOfLicense") === "Demolición" && (
        <div className="flex flex-col w-full gap-3">
          <label className="font-medium text-base" htmlFor="license">
            Tipo de demolición *
          </label>
          <select
            className="h-[55px] border border-grayTextConsultory rounded cursor-pointer"
            id="license"
            defaultValue=""
            onChange={(e) => setValue("typeOfIdentification", e.target.value)}
            {...register("typeOfDemolition", { required: true })}
          >
            <option value="" disabled>
              Seleccione
            </option>
            <option value="Total">Total</option>
            <option value="Parcial">Parcial</option>
          </select>
          {errors.typeOfDemolition?.type === "required" && (
            <span className="text-red-700">Este campo es requerido</span>
          )}
        </div>
      )}
      {/*Type of applicant*/}
      <div className="flex flex-col w-full gap-3">
        <label className="font-medium text-base" htmlFor="applicationType">
          Tipo de solicitante *
        </label>
        <select
          className={`h-[55px] border border-grayTextConsultory rounded cursor-pointer`}
          id="applicationType"
          defaultValue=""
          onChange={(e) => setValue("applicationType", e.target.value)}
          {...register("applicationType", { required: true })}
        >
          <option value="" disabled hidden>
            Seleccione
          </option>
          <option value="Persona jurídica">Persona jurídica</option>
          <option value="Persona natural">Persona natural</option>
        </select>
        {errors.applicationType?.type === "required" && (
          <span className="text-red-700">Este campo es requerido</span>
        )}
      </div>
      <div
        className={`w-full space-y-3 ${
          values.applicationType === "Persona jurídica" ? "sm:w-full" : "sm:flex sm:justify-between"
        }  sm:space-y-0`}
      >
        {values.applicationType !== "Persona jurídica" && (
          <div className="flex flex-col gap-3">
            <label className="font-medium text-base" htmlFor="identification">
              Tipo de identificación *
            </label>
            <select
              className="h-[55px] border border-grayTextConsultory rounded sm:w-[280px]"
              id="identification"
              defaultValue=""
              onChange={(e) => setValue("typeOfIdentification", e.target.value)}
              {...register("typeOfIdentification", { required: true })}
            >
              <option value="" disabled hidden>
                Seleccione
              </option>
              <option value="Cedula de ciudadania">Cédula de ciudadanía</option>
              <option value="Cédula de ciduadanía">Cédula de extranjería</option>
            </select>
            {errors.typeOfIdentification?.type === "required" && (
              <span className="text-red-700">Este campo es requerido</span>
            )}
          </div>
        )}
        {/* ID and Nit*/}
        {values.applicationType === "Persona jurídica" ? (
          <div className="flex flex-col gap-3">
            <FormInput
              label="nit"
              name="Nit *"
              placeholder="900.090.125-1"
              width="w-full"
              rules={{
                required: true,
                minLength: 9,
                maxLength: 9,
              }}
              type="number"
              register={register}
              errors={errors}
            />
            {errors.nit?.type === "minLength" && (
              <span className="text-red-700">El nit debe contener 9 digitos</span>
            )}
            {errors.nit?.type === "maxLength" && (
              <span className="text-red-700">El nit debe contener máximo 9 digitos</span>
            )}
          </div>
        ) : (
          <div className="flex flex-col gap-3">
            <FormInput
              label="document"
              name="Número de identificación *"
              placeholder="7700809"
              rules={{
                maxLength: 10,
              }}
              type="number"
              register={register}
              width="sm:w-[280px]"
              errors={errors}
            />

            {errors.document?.type === "maxLength" && (
              <span className="text-red-700">El máximo de digitos son 10</span>
            )}
          </div>
        )}
      </div>
      {/* Enterprise */}
      {values.applicationType === "Persona jurídica" && (
        <div className="w-full space-y-3 ">
          <>
            <FormInput
              label="enterprise"
              name="Empresa *"
              placeholder="Indoles"
              rules={{
                required: true,
                minLength: 3,
                maxLength: 50,
              }}
              type="text"
              register={register}
              errors={errors}
            />

            {errors.enterprise?.type === "minLength" && (
              <span className="text-red-700">Debe tener minimo 3 caracteres</span>
            )}
            {errors.nit?.type === "maxLength" && (
              <span className="text-red-700">Puede tener máximo 50 caracteres</span>
            )}
          </>
        </div>
      )}
      {/* Name and email */}
      <div className="w-full space-y-3 sm:flex sm:w-full sm:justify-between sm:space-y-0">
        <div className="flex flex-col gap-3">
          <FormInput
            width="sm:w-[280px]"
            label="name"
            name="Nombres y apellidos *"
            rules={{
              required: true,
              minLength: 3,
              pattern: /^[A-Za-z\s]+$/,
            }}
            placeholder="Alexander Reyes"
            type="text"
            register={register}
            errors={errors}
          />

          {errors.name?.type === "minLength" && (
            <span className="text-red-700">El nombre debe contener mas de 3 caracteres</span>
          )}
          {errors.name?.type === "pattern" && (
            <span className="text-red-700">El nombre solo puede contener letras</span>
          )}
        </div>
        <div className="flex flex-col gap-3">
          <FormInput
            label="email"
            name="Correo electrónico *"
            width="sm:w-[280px]"
            rules={{
              required: true,
              pattern: /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/i,
            }}
            placeholder="ingenieria@indolesltda.com"
            type="email"
            register={register}
            errors={errors}
          />
          {errors.email?.type === "pattern" && (
            <span className="text-red-700">El correo no sigue el patron correcto</span>
          )}
        </div>
      </div>
      {/* City and Number phone */}
      <div className="w-full space-y-3 sm:flex sm:w-full sm:justify-between sm:space-y-0">
        <div className="flex flex-col gap-3">
          <FormInput
            label="city"
            width="sm:w-[280px]"
            name="Ciudad *"
            placeholder="Neiva"
            rules={{
              required: true,
            }}
            type="text"
            register={register}
            errors={errors}
          />
        </div>
        <div className="flex flex-col gap-3">
          <FormInput
            label="numberPhone"
            width="sm:w-[280px]"
            name="Teléfono *"
            placeholder="3186081940"
            rules={{
              required: true,
              minLength: 10,
              maxLength: 10,
            }}
            type="number"
            register={register}
            errors={errors}
          />
          {errors.numberPhone?.type === "minLength" ||
            (errors.numberPhone?.type === "maxLength" && (
              <span className="text-red-700">El número debe contener 10 digitos</span>
            ))}
        </div>
      </div>
      {/* State and Country */}
      <div className="w-full space-y-3 sm:flex sm:w-full sm:justify-between sm:space-y-0">
        <div className="flex flex-col gap-3">
          <FormInput
            label="state"
            name="Departamento *"
            placeholder="Huila"
            rules={{
              required: true,
            }}
            type="text"
            register={register}
            width="sm:w-[280px]"
            errors={errors}
          />
        </div>
        <div className="flex flex-col gap-3">
          <FormInput
            label="country"
            name="País *"
            placeholder="Colombia"
            rules={{
              required: true,
            }}
            type="text"
            register={register}
            errors={errors}
            width="sm:w-[280px]"
          />
          {errors.numberPhone?.type === "minLength" ||
            (errors.numberPhone?.type === "maxLength" && (
              <span className="text-red-700">El número debe contener 10 digitos</span>
            ))}
        </div>
      </div>
      <div className="flex flex-col w-full gap-3">
        <label className="font-medium text-base" htmlFor="description">
          Descripción
        </label>
        <textarea
          className="h-[200px] px-2 border border-grayTextConsultory rounded "
          id="description"
          placeholder="Descripción del trámite"
          {...register("description")}
        ></textarea>
      </div>
      <CheckBox errors={errors} label="termsOfUse" register={register} rules={{ required: true }} />
      <ButtonSendForm />
    </form>
  )
}
