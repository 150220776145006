import React from "react"

export default function UnderSide() {
  const socialNetworks = [
    {
      id: 1,
      name: "Instagram",
      url: "https://www.instagram.com/indolesltda/",
      icon: "/socialNetworks/instagram.svg",
    },
    {
      id: 2,
      name: "Facebook",
      url: "https://www.facebook.com/profile.php?id=61550845515120",
      icon: "/socialNetworks/facebook.svg",
    },
    {
      id: 3,

      name: "Whatsapp",
      url: "https://wa.link/vjkynf",
      icon: "/socialNetworks/whatsapp.svg",
    },
    {
      id: 4,
      name: "Linkedin",
      icon: "/socialNetworks/linkedin.svg",
      url: "https://www.linkedin.com/company/98707204/admin/feed/posts/?feedType=following",
    },
  ]
  const linksServices = [
    { id: 1, name: "Consultoría", url: "/Consultoría" },
    { id: 2, name: "Diseño Estructural", url: "/Diseño-estructural" },
    { id: 3, name: "Licencias de construcción", url: "/Licencias-de-construcción" },
    { id: 4, name: "Costos y presupuestos", url: "/Costos-y-presupuestos" },
  ]
  const linksSupport = [
    { id: 1, name: "Contacto", url: "/Contáctanos" },
    { id: 2, name: "Nosotros", url: "/Nosotros" },
  ]
  const linsksPolicies = [
    {
      id: 1,
      name: "Política de protección de datos personales",
      url: "https://comercio.indolesltda.com/pol%C3%ADtica-de-protecci%C3%B3n-de-datos-personales/",
    },
    {
      id: 2,
      name: "Política de tratamiento de la información",
      url: "https://comercio.indolesltda.com/pol%C3%ADtica-de-tratamiento-de-la-informaci%C3%B3n/",
    },
    {
      id: 3,
      name: "Política de envíos, devolución y reembolso",
      url: "https://comercio.indolesltda.com/pol%C3%ADtica-de-env%C3%ADos-devoluciones-y-reembolsos/",
    },
    {
      id: 4,
      name: "Política de garantías",
      url: "https://comercio.indolesltda.com/pol%C3%ADtica-de-garant%C3%ADa/",
    },
    {
      id: 5,
      name: "Política de uso de cookies",
      url: "https://comercio.indolesltda.com/pol%C3%ADtica-de-uso-de-cookies/",
    },
    {
      id: 6,
      name: "Protección del consumidor",
      url: "https://www.sic.gov.co/tema/proteccion-del-consumidor",
    },
    {
      id: 7,
      name: "Superintendencia de Industria y Comercio SIC",
      url: "https://www.sic.gov.co/",
    },
    {
      id: 8,
      name: "Medios de pago",
      url: "https://comercio.indolesltda.com/metodos-de-pago/",
    },
    {
      id: 9,
      name: "PQRS",
      url: "/Contáctanos",
    },
  ]
  return (
    <footer className="flex flex-col w-full items-center bg-blueBgFooter ">
      <nav className="grid px-6 mt-6 gap-x-4 gap-y-4 md:grid-cols-2 lg:grid-cols-4 ">
        <div className="flex flex-col md:items-start lg:items-center lg:w-[200px] xl:w-[248px] gap-5">
          <h2 className="relative text-white text-base font-bold">
            Indoles LTDA
            <span className="absolute w-[50px] h-[1px] bg-greenClaroLogo bottom-0 left-0"></span>
          </h2>
          <p className="text-justify text-sm text-grayFontFooter">
            Empresa líder en el sector de la Ingeniería Civil. Nos encargamos de llevar tus
            necesidades al espacio real
          </p>
        </div>
        <div className="flex flex-col md:items-start lg:items-center gap-5">
          <h2 className="relative text-white text-base font-bold">
            Servicios
            <span className="absolute w-[50px] h-[1px] bg-greenClaroLogo bottom-0 left-0"></span>
          </h2>
          <ul className="text-grayFontFooter gap-2">
            {linksServices.map((link) => (
              <li key={link.id}>
                <a href={link.url}>{link.name}</a>
              </li>
            ))}
          </ul>
        </div>
        <div className="flex flex-col md:items-start lg:items-center gap-5">
          <h2 className="relative text-white text-base font-bold">
            Soporte
            <span className="absolute w-[50px] h-[1px] bg-greenClaroLogo bottom-0 left-0"></span>
          </h2>
          <ul className="text-grayFontFooter gap-2">
            {linksSupport.map((link) => (
              <li key={link.id}>
                <a href={link.url}>{link.name}</a>
              </li>
            ))}
            {linsksPolicies.map((link) => (
              <li key={link.id}>
                <a href={link.url} target="_blank" rel="noopener noreferrer">
                  {link.name}
                </a>
              </li>
            ))}
          </ul>
        </div>
        {/* Support */}
        <div className="flex flex-col md:items-start lg:items-center gap-5">
          <h2 className="relative text-white text-base font-bold">
            Contacto
            <span className="absolute w-[50px] h-[1px] bg-greenClaroLogo  bottom-0 left-0"></span>
          </h2>
          <ul className="font-normal text-grayFontFooter text-sm">
            <li className="flex gap-1.5 mb-1">
              <img width={24} height={24} src="/icons/contact/envelope.svg" alt="Contact Email" />
              <div>
                <a className="block mb-1 hover:underline" href="mailto:indolesltda@gmail.com">
                  indolesltda@gmail.com
                </a>
                <a
                  className="block mb-1 hover:underline"
                  href="mailto:contabilidad@indolesltda.com"
                >
                  contabilidad@indolesltda.com
                </a>
                <a
                  className="block mb-1 hover:underline"
                  href="mailto:administrador@indolesltda.com"
                >
                  administrador@indolesltda.com
                </a>
                <a className="block mb-1 hover:underline" href="mailto:ingenieria@indolesltda.com">
                  ingenieria@indolesltda.com
                </a>
                <a className="block mb-1 hover:underline" href="mailto:comercial@indolesltda.com">
                  comercial@indolesltda.com
                </a>
                <a className="block mb-1 hover:underline" href="mailto:pqrs@indolesltda.com">
                  pqrs@indolesltda.com
                </a>
              </div>
            </li>
            <li className="flex gap-1.5 items-center mb-1">
              <img width={24} height={24} src="/icons/house.svg" alt="Office" />
              <div>
                <address className="block not-italic">
                  Sede Neiva: Carrera 1A 36-24 Neiva Huila Colombia
                </address>
                <address className="block not-italic">Sede Bogotá: </address>
              </div>
            </li>
            <li className="flex gap-1.5">
              <img width={24} height={24} src="icons/contact/telephone.svg" alt="Contact Number" />
              <span>Línea única: +573186081940</span>
            </li>
          </ul>
        </div>
      </nav>
      {/* Social Networks */}
      <nav className="my-2.5">
        <ul className="inline-flex items-start gap-6">
          {socialNetworks.map((socialNetwork) => (
            <li key={socialNetwork.id}>
              <a href={socialNetwork.url}>
                <img
                  src={socialNetwork.icon}
                  alt={`Follow us on ${socialNetwork.name}`}
                  width={24}
                  height={24}
                />
              </a>
            </li>
          ))}
        </ul>
      </nav>
      <section className="flex w-full h-12 justify-center items-center bg-grayBgFooter ">
        <span>copyright: Indoles LTDA</span>
      </section>
    </footer>
  )
}
