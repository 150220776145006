import React from "react"
import Quote from "../components/Forms/Quote"
import ButtonWhatsapp from "../components/Buttons/ButtonWhatsapp"
export default function Quotes() {
  return (
    <main>
      <div className="flex flex-col w-full py-12 px-10 justify-center items-center space-y-5 xl:my-[100px] sm:px-0">
        <h2 className="font-bold text-center text-3xl text-zinc-700 ">
          COTIZA CON NOSOTROS
        </h2>
        <p className="text-zinc-600 text-justify sm:w-[600px]">
          Tu cotización es muy importante para nosotros, por favor diligencia el
          siguiente formulario. El equipo de Indoles se pondrá en contacto
          contigo lo antes posible para apoyarte con tu solicitud .😄👷🏗️. El
          canal de comunicación que prefieras. <br />
          Presiona el icono de whatsapp y te atenderemos.📱
        </p>
        <Quote />
      </div>
      <ButtonWhatsapp />
    </main>
  )
}
