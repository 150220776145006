// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `@keyframes slideUp {
  0% {
    opacity: 0;
    transform: translateY(20px); /* scrolldown */
  }
  100% {
    opacity: 1;
    transform: translateY(0); /* No displacement */
  }
}

.animate-slide-up {
  animation: slideUp 1s ease forwards; /* Animation duration and time function */
}
`, "",{"version":3,"sources":["webpack://./src/styles/Home.css"],"names":[],"mappings":"AAAA;EACE;IACE,UAAU;IACV,2BAA2B,EAAE,eAAe;EAC9C;EACA;IACE,UAAU;IACV,wBAAwB,EAAE,oBAAoB;EAChD;AACF;;AAEA;EACE,mCAAmC,EAAE,yCAAyC;AAChF","sourcesContent":["@keyframes slideUp {\n  0% {\n    opacity: 0;\n    transform: translateY(20px); /* scrolldown */\n  }\n  100% {\n    opacity: 1;\n    transform: translateY(0); /* No displacement */\n  }\n}\n\n.animate-slide-up {\n  animation: slideUp 1s ease forwards; /* Animation duration and time function */\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
